<template>
  <Navbar />
  <main id="main" class="container-fluid pt-3">
    <h3>けんさく結果</h3>
    <div class="d-md-flex justify-content-between mb-1">
      <Breadcrumb :breadcrumbs="breadcrumbs" />
      <Pagination
        :count="count"
        :prev="prev"
        :next="next"
        :current_page="current_page"
        :total_pages="total_pages"
        :page_range="5"
        :show_count="true"
        @move-page="movePage"
      />
    </div>

    <ul v-if="pages && pages.length > 0" class="list-group">
      <router-link
        v-for="page in pages"
        :key="page.id"
        :to="{
          name: 'FrontPageDetail',
          params: { id: page.id },
        }"
        class="list-group-item d-flex justify-content-between align-items-center p-3"
      >
        {{ page.title }}
        <i class="bi bi-arrow-right-circle"></i>
      </router-link>
    </ul>
    <p v-else class="p-0 m-0">見つかりませんでした。</p>
  </main>

  <Footer />
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Navbar from '@/components/TheNavbar.vue'
import Footer from '@/components/TheFooter.vue'
import Breadcrumb from '@/components/AppBreadcrumb.vue'
import Pagination from '@/components/AppPagination.vue'

export default {
  name: 'FrontPageList',
  components: {
    Navbar,
    Footer,
    Breadcrumb,
    Pagination,
  },

  data() {
    return {
      query: {},
      breadcrumbs: [
        {
          name: 'けんさく結果',
          url: false,
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      pages: 'frontend_page/pages',
      count: 'frontend_page/count',
      prev: 'frontend_page/prev',
      next: 'frontend_page/next',
      current_page: 'frontend_page/current_page',
      total_pages: 'frontend_page/total_pages',
      query_params: 'frontend_page/query_params',
    }),
  },

  async beforeRouteUpdate(to, from, next) {
    this.query = { content: to.query.q }
    await this.getQueryResult()
    next()
  },

  async created() {
    this.query = { content: this.$route.query.q }
    await this.getQueryResult()
  },

  unmounted() {
    this.resetQuery()
  },

  methods: {
    ...mapActions({
      setQuery: 'frontend_page/setQuery',
      getData: 'frontend_page/fetchAll',
    }),
    movePage(page) {
      this.getData(Object.assign(this.query, { page: page }))
    },
    getQueryResult() {
      this.setQuery(this.query).then(() => {
        this.getData()
      })
    },
    resetQuery() {
      this.query = {}
      this.getQueryResult()
    },
  },
}
</script>
