import { createRouter, createWebHistory } from 'vue-router'

import AppHome from '@/pages/AppHome.vue'
import AppLogin from '@/pages/AppLogin.vue'
import NotFound from '@/pages/NotFound.vue'
import FrontCategoryList from '@/pages/FrontCategoryList.vue'
import FrontPageList from '@/pages/FrontPageList.vue'
import FrontPageDetail from '@/pages/FrontPageDetail.vue'

import Manage from './manage'

// store
import store from '@/store'

const routes = [
  {
    path: '/',
    component: AppHome,
    name: 'AppHome',
    meta: { requiresAuth: true },
  },
  {
    path: '/login',
    component: AppLogin,
    name: 'AppLogin',
  },
  {
    path: '/category/:id([0-9]+)',
    component: FrontCategoryList,
    name: 'FrontCategoryList',
    props: (route) => ({ id: Number(route.params.id) }),
    meta: { requiresAuth: true },
  },
  {
    path: '/pages',
    component: FrontPageList,
    name: 'FrontPageList',
    meta: { requiresAuth: true },
  },
  {
    path: '/page/:id([0-9]+)',
    component: FrontPageDetail,
    name: 'FrontPageDetail',
    props: (route) => ({ id: Number(route.params.id) }),
    meta: { requiresAuth: true },
  },
  ...Manage.routes,
  {
    path: '/:pathMatch(.*)*',
    component: NotFound,
    name: 'NotFound',
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes: routes,
})

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth && !store.getters['auth/is_logged_in']) {
    if (store.getters['auth/is_token']) {
      store.dispatch('auth/renew').then(() => {
        next()
      })
    } else {
      router.replace({
        name: 'AppLogin',
        query: { redirect: to.fullPath },
      })
    }
  } else {
    next()
  }
})

export default router
