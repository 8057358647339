<template>
  <div class="page_wrapper">
    <div v-html="page.content" class="page_content ck-content"></div>
  </div>
</template>

<script>
export default {
  name: 'TemplateTextOnly',
  components: {},

  props: {
    page: {
      type: Object,
      required: true,
    },
  },
}
</script>
