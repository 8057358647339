<template>
  <div
    class="modal fade"
    id="changePasswordModal"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content text-body">
        <div class="modal-header">
          <h5 class="modal-title">
            <slot name="modalTitle">パスワードを変更</slot>
          </h5>
        </div>
        <div class="modal-body">
          <FormKit
            type="form"
            @submit="formSubmit"
            :actions="false"
            :incomplete-message="false"
            :id="form_id"
          >
            <div class="row g-3">
              <div class="col-12">
                <FormKit
                  v-model="user.new_password"
                  type="password"
                  name="new_password"
                  label="パスワード"
                  validation="required"
                />
              </div>
              <div class="col-12">
                <FormKit
                  v-model="user.re_new_password"
                  type="password"
                  name="re_new_password"
                  label="パスワード（再入力）"
                  validation="confirm:new_password"
                  placeholder="確認のためもう一度入力してください"
                />
              </div>
              <div class="col-12">
                <FormKit
                  v-model="user.current_password"
                  type="password"
                  name="current_password"
                  label="現在のパスワード"
                  validation="required:trim"
                />
              </div>
            </div>
          </FormKit>
        </div>
        <div class="modal-footer">
          <button
            class="btn btn-outline-dark"
            type="button"
            @click.prevent="formClose"
          >
            キャンセル
          </button>
          <FormKit type="submit" :form="form_id"> 保存 </FormKit>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { Modal } from 'bootstrap/dist/js/bootstrap.esm.min.js'

export default {
  name: 'ProfileChangePassword',
  components: {},

  emits: ['hiddenModal', 'afterSubmit'],

  data() {
    return {
      modal: null,
      form_id: 'ChangePasswordForm',
      user: {},
    }
  },
  computed: {},

  mounted() {
    this.modal = new Modal(document.getElementById('changePasswordModal'))
    document
      .getElementById('changePasswordModal')
      .addEventListener('hidden.bs.modal', () => {
        this.user = {}
        this.$emit('hiddenModal')
      })
  },
  beforeUnmount() {
    this.modal.hide()
  },

  methods: {
    ...mapActions({
      setPassword: 'auth/setPassword',
    }),
    showModal() {
      this.modal.show()
    },
    async formSubmit() {
      try {
        await this.setPassword(this.user)
        await this.$emit('afterSubmit')
        this.formClose()
      } catch (e) {
        if (e.response.status == 400) {
          // サーバー側の検証エラーを表示
          let errors = {}
          Object.keys(e.response.data).forEach((key) => {
            errors[key] = e.response.data[key][0]
          })
          this.$formkit.setErrors(this.form_id, [], errors)
        }
      }
    },
    formClose() {
      this.$formkit.reset(this.form_id)
      this.modal.hide()
    },
  },
}
</script>
