<template>
  <div class="page_links mb-3">
    <div class="d-flex align-items-center justify-content-between mb-2">
      <h5 class="m-0 p-0">関連リンク</h5>
      <a @click.prevent="showForm" href="#" class="text-secondary"
        ><i class="bi bi-plus-circle-fill me-1"></i>追加</a
      >
    </div>
    <div v-if="links && links.length > 0">
      <div
        v-for="(link, index) in links"
        :key="link.id"
        class="input-group mb-1"
      >
        <input type="text" class="form-control" :value="link.title" readonly />
        <button
          class="btn btn-danger"
          type="button"
          @click.prevent="removeData(index)"
        >
          <i class="bi bi-trash3"></i>
        </button>
      </div>
    </div>
    <p v-else class="p-0 m-0">関連リンクはありません。</p>
  </div>

  <div
    class="modal fade"
    id="pageLinkModal"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content text-body">
        <div class="modal-header">
          <h5 class="modal-title">
            <slot name="modalTitle">関連リンクを追加</slot>
          </h5>
        </div>
        <div class="modal-body">
          <FormKit
            type="form"
            @submit="insertData"
            :actions="false"
            :incomplete-message="false"
            :id="form_id"
          >
            <div class="row g-3">
              <div class="col-12">
                <FormKit
                  v-model="form_data.title"
                  type="text"
                  name="title"
                  label="リンクタイトル"
                  validation="required"
                />
              </div>
              <div class="col-12">
                <FormKit
                  v-model="form_data.url"
                  type="text"
                  name="url"
                  label="リンクURL"
                  validation="required|url"
                />
              </div>
            </div>
          </FormKit>
        </div>
        <div class="modal-footer">
          <button
            class="btn btn-outline-dark"
            type="button"
            @click.prevent="hideForm"
          >
            キャンセル
          </button>
          <FormKit type="submit" :form="form_id"> 追加 </FormKit>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from 'bootstrap/dist/js/bootstrap.esm.min.js'

export default {
  name: 'PageLinkOperate',

  emits: ['removedData', 'insertData'],
  props: {
    links: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      modal: null,
      form_id: 'PageLinkForm',
      form_data: {},
    }
  },

  mounted() {
    this.modal = new Modal(document.getElementById('pageLinkModal'))
  },
  beforeUnmount() {
    this.modal.hide()
  },

  methods: {
    showForm() {
      this.modal.show()
    },
    async hideForm() {
      await this.$formkit.reset(this.form_id)
      this.modal.hide()
    },
    removeData(index) {
      this.$emit('removedData', index)
    },
    async insertData(values) {
      await this.$emit('insertData', values)
      this.hideForm()
    },
  },
}
</script>
