<template>
  <router-view></router-view>
  <Footer />
</template>

<script>
import Footer from '@/components/TheFooter.vue'

export default {
  name: 'ManageHome',
  components: {
    Footer,
  },
}
</script>
