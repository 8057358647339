<template>
  <Navbar @submit="formSubmit" @cancel="formCancel"
    ><template v-slot:formTitle>ページ編集</template></Navbar
  >
  <main id="main" class="container-fluid">
    <div class="py-3">
      <div class="card card-body">
        <PageForm :form_id="form_id" :form_data="form_data" />
      </div>
    </div>
  </main>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Navbar from '@/components/TheNavbarForm.vue'
import PageForm from '@/components/page/PageForm.vue'

export default {
  name: 'ManagePageEdit',

  components: {
    Navbar,
    PageForm,
  },

  props: {
    id: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      form_id: 'pageForm',
      form_data: {},
    }
  },
  computed: {
    ...mapGetters({
      page: 'manage_page/detail',
    }),
  },

  async created() {
    await this.getData(this.id).catch((error) => {
      if (error.response.status) {
        window.setTimeout(() => {
          this.$router.push({
            name: 'ManagePageList',
          })
        }, this.$store.getters['alert/timeout'])
      }
    })
    this.form_data = Object.assign({}, JSON.parse(JSON.stringify(this.page)))
  },

  methods: {
    ...mapActions({
      getData: 'manage_page/fetchData',
    }),
    formSubmit() {
      // フォーム検証と送信
      this.$formkit.submit(this.form_id)
    },
    formCancel() {
      this.$store.commit('manage_page/clearDetail')
      this.$router.push({
        name: 'ManagePageDetail',
        params: { id: this.id },
      })
    },
  },
}
</script>
