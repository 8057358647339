<template>
  <div
    class="modal fade"
    id="updateSubcategoryModal"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            <slot name="modalTitle">項目を編集</slot>
          </h5>
        </div>
        <div class="modal-body">
          <FormKit
            type="form"
            @submit="formSubmit"
            :actions="false"
            :incomplete-message="false"
            :id="form_id"
          >
            <div class="row g-3">
              <div class="col-12">
                <FormKit
                  v-model="form_data.category_id"
                  type="select"
                  name="category_id"
                  label="大項目"
                  validation="required"
                >
                  <option
                    v-for="category in category_list"
                    :key="category.id"
                    :value="category.id"
                  >
                    {{ category.name }}
                  </option>
                </FormKit>
              </div>
              <div class="col-12">
                <FormKit
                  v-model="form_data.name"
                  type="text"
                  name="name"
                  label="項目名"
                  validation="required"
                />
              </div>
            </div>
          </FormKit>
        </div>
        <div class="modal-footer">
          <button
            class="btn btn-outline-dark"
            type="button"
            @click.prevent="formClose"
          >
            キャンセル
          </button>
          <FormKit type="submit" :form="form_id"> 保存 </FormKit>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { Modal } from 'bootstrap/dist/js/bootstrap.esm.min.js'

export default {
  name: 'SubcategoryUpdateForm',
  components: {},

  emits: ['hiddenModal', 'afterSubmit'],

  data() {
    return {
      modal: null,
      form_id: 'UpdateSubcategoryForm',
      form_data: {},
    }
  },
  computed: {
    ...mapGetters({
      category_list: 'manage_subcategory/category_list',
    }),
  },

  async created() {
    await this.fetchCategoryList()
  },

  mounted() {
    this.modal = new Modal(document.getElementById('updateSubcategoryModal'))
    document
      .getElementById('updateSubcategoryModal')
      .addEventListener('hidden.bs.modal', () => {
        this.form_data = {}
        this.$emit('hiddenModal')
      })
  },
  beforeUnmount() {
    this.modal.hide()
  },

  methods: {
    ...mapActions({
      patchData: 'manage_subcategory/patchData',
      fetchCategoryList: 'manage_subcategory/fetchCategoryList',
    }),
    showModal(subcategory) {
      if (subcategory) {
        this.form_data = Object.assign({}, subcategory)
      }
      this.modal.show()
    },
    async formSubmit() {
      try {
        await this.patchData(this.form_data)
        await this.$emit('afterSubmit')
        this.formClose()
      } catch (e) {
        if (e.response.status == 400) {
          // サーバー側の検証エラーを表示
          let errors = {}
          Object.keys(e.response.data).forEach((key) => {
            errors[key] = e.response.data[key][0]
          })
          this.$formkit.setErrors(this.form_id, [], errors)
        }
      }
    },
    formClose() {
      this.$formkit.reset(this.form_id)
      this.modal.hide()
    },
  },
}
</script>
