<template>
  <Navbar />
  <main id="main" class="container-fluid pt-3">
    <div class="clearfix">
      <div class="float-end"><PageComments :comments="page.comments" /></div>

      <h3>{{ page.title }}</h3>
      <div class="d-md-flex justify-content-between mb-1">
        <Breadcrumb v-if="page.breadcrumbs" :breadcrumbs="page.breadcrumbs" />
        <div class="d-flex align-items-center justify-content-start gap-2">
          <nav>
            <ul class="pagination m-0">
              <li class="page-item" :class="page.previous_id ? '' : 'disabled'">
                <router-link
                  v-if="page.previous_id"
                  :to="{
                    name: 'FrontPageDetail',
                    params: { id: page.previous_id },
                  }"
                  class="page-link"
                >
                  <span aria-hidden="true">&laquo;</span>
                </router-link>
                <span v-else class="page-link" aria-hidden="true">&laquo;</span>
              </li>
              <li class="page-item" :class="page.next_id ? '' : 'disabled'">
                <router-link
                  v-if="page.next_id"
                  :to="{
                    name: 'FrontPageDetail',
                    params: { id: page.next_id },
                  }"
                  class="page-link"
                >
                  <span aria-hidden="true">&raquo;</span>
                </router-link>
                <span v-else class="page-link" aria-hidden="true">&raquo;</span>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>

    <div class="shadow-sm rounded p-1 p-md-2 p-lg-3 mb-2">
      <TemplateOneMedia v-if="page.template == 'one_media'" :page="page" />
      <TemplateTwoMedia v-if="page.template == 'two_media'" :page="page" />
      <TemplateThreeMedia v-if="page.template == 'three_media'" :page="page" />
      <TemplateTextOnly v-if="page.template == 'text_only'" :page="page" />
      <TemplateTwoAndTwo v-if="page.template == 'two_two'" :page="page" />
      <TemplateSlider v-if="page.template == 'slider'" :page="page" />
    </div>

    <PageLinks :links="page.links" />
  </main>

  <Footer />
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Navbar from '@/components/TheNavbar.vue'
import Footer from '@/components/TheFooter.vue'
import Breadcrumb from '@/components/AppBreadcrumb.vue'
import Pagination from '@/components/AppPagination.vue'
import PageComments from '@/components/page/PageComments.vue'
import PageLinks from '@/components/page/PageLinks.vue'
import TemplateOneMedia from '@/components/template/TemplateOneMedia.vue'
import TemplateTwoMedia from '@/components/template/TemplateTwoMedia.vue'
import TemplateThreeMedia from '@/components/template/TemplateThreeMedia.vue'
import TemplateTextOnly from '@/components/template/TemplateTextOnly.vue'
import TemplateTwoAndTwo from '@/components/template/TemplateTwoAndTwo.vue'
import TemplateSlider from '@/components/template/TemplateSlider.vue'

export default {
  name: 'FrontPageDetail',
  components: {
    Navbar,
    Footer,
    Breadcrumb,
    Pagination,
    PageComments,
    PageLinks,
    TemplateOneMedia,
    TemplateTwoMedia,
    TemplateThreeMedia,
    TemplateTextOnly,
    TemplateTwoAndTwo,
    TemplateSlider,
  },

  props: {
    id: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {}
  },
  computed: {
    ...mapGetters({
      page: 'frontend_page/detail',
    }),
  },
  watch: {
    id: function () {
      this.getPageData()
    },
  },

  async created() {
    this.getPageData()
  },

  methods: {
    ...mapActions({
      getData: 'frontend_page/fetchData',
    }),
    async getPageData() {
      await this.getData(this.id).catch((error) => {
        if (error.response.status) {
          window.setTimeout(() => {
            this.$router.push({
              name: 'AppHome',
            })
          }, this.$store.getters['alert/timeout'])
        }
      })
      this.page.content = await this.replaceContent()
    },
    replaceContent() {
      if (!this.page.content) return ''
      const regex = /<div\s+class\s*=\s*["']page-break["'].*?>.*?<\/div>/gi
      const replacement = '<!--page_break-->'
      return this.page.content.replace(regex, replacement)
    },
  },
}
</script>
