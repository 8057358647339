<template>
  <div class="page_wrapper row g-3">
    <div class="col-12 col-lg-3">
      <div class="row g-2">
        <div class="col-md-4 col-lg-12">
          <div v-if="isVideo(file_1)">
            <VideoPlayer :file="file_1" />
          </div>
          <div v-else>
            <ImageViewer :file="file_1" />
          </div>
        </div>

        <div class="col-md-4 col-lg-12">
          <div v-if="isVideo(file_2)">
            <VideoPlayer :file="file_2" />
          </div>
          <div v-else>
            <ImageViewer :file="file_2" />
          </div>
        </div>

        <div class="col-md-4 col-lg-12">
          <div v-if="isVideo(file_3)">
            <VideoPlayer :file="file_3" />
          </div>
          <div v-else>
            <ImageViewer :file="file_3" />
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-lg-9">
      <div v-html="page.content" class="page_content ck-content"></div>
    </div>
  </div>
</template>

<script>
import ImageViewer from '@/components/AppImageViewer.vue'
import VideoPlayer from '@/components/AppVideoPlayer.vue'
import { isVideo } from '@/utils/fileTypeUtils.js'

export default {
  name: 'TemplateThreeMedia',
  components: {
    ImageViewer,
    VideoPlayer,
  },

  props: {
    page: {
      type: Object,
      required: true,
    },
  },

  computed: {
    file_1: function () {
      if (!this.page.files[0]) return {}
      return this.page.files[0]
    },
    file_2: function () {
      if (!this.page.files[1]) return {}
      return this.page.files[1]
    },
    file_3: function () {
      if (!this.page.files[2]) return {}
      return this.page.files[2]
    },
  },

  methods: {
    isVideo,
  },
}
</script>
