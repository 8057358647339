<template>
  <Navbar />
  <main
    v-if="page && Object.keys(page).length > 0"
    id="main"
    class="container-fluid"
  >
    <header class="border-bottom py-2 py-sm-3">
      <div class="d-flex align-items-center justify-content-between">
        <div class="operation_links">
          <router-link
            :to="{
              name: 'ManagePageList',
            }"
            class="btn btn-outline-dark"
            ><i class="bi bi-list"></i>
            <span class="d-none d-sm-inline-block ms-1"
              >一覧に戻る</span
            ></router-link
          >
        </div>
        <div class="operation_links">
          <button class="btn btn-info" v-on:click.prevent="showPreview">
            <span class="d-block d-sm-none"><i class="bi bi-eye"></i></span>
            <span class="d-none d-sm-block"
              ><i class="bi bi-eye me-1"></i>プレビュー</span
            >
          </button>
          <router-link
            :to="{
              name: 'ManagePageEdit',
              params: { id: page.id },
            }"
            class="btn btn-success"
          >
            <span class="d-block d-sm-none"><i class="bi bi-pencil"></i></span>
            <span class="d-none d-sm-block"
              ><i class="bi bi-pencil me-1"></i>編集</span
            ></router-link
          >
          <button class="btn btn-danger" v-on:click.prevent="removeData">
            <span class="d-block d-sm-none"><i class="bi bi-trash"></i></span>
            <span class="d-none d-sm-block"
              ><i class="bi bi-trash me-1"></i>削除</span
            >
          </button>
        </div>
      </div>
    </header>

    <div class="clearfix py-3">
      <h3 class="d-inline align-middle me-2">{{ page.title }}</h3>
      <div class="d-inline">
        <span v-if="page.is_publish" class="badge rounded-pill bg-success"
          >公開</span
        >
        <span v-else class="badge rounded-pill bg-light">非公開</span>
        <span class="badge rounded-pill bg-secondary ms-1">{{
          page.template_name
        }}</span>
      </div>
    </div>
    <div class="row g-3">
      <div class="col-lg-8">
        <div class="row g-3">
          <div class="col-12">
            <i class="bi bi-tag me-1"></i
            ><span v-if="page.subcategory_name">{{
              page.subcategory_name
            }}</span
            ><span v-else>項目なし</span>
          </div>
          <div v-html="page.content" class="col-12 ck-content"></div>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="page_links mb-3">
          <h5>関連リンク</h5>
          <ul v-if="page.links && page.links.length > 0">
            <li v-for="link in page.links" :key="link.id">
              <a :href="link.url" target="_blank">{{ link.title }}</a>
            </li>
          </ul>
          <p v-else class="p-0 m-0">関連リンクはありません。</p>
        </div>
        <div class="page_comments mb-3">
          <h5>コメント</h5>
          <div
            v-if="page.comments && page.comments.length > 0"
            class="row row-cols-1 gy-2"
          >
            <div v-for="comment in page.comments" :key="comment.id" class="col">
              <div class="card card-body">
                {{ comment.comment }}
              </div>
            </div>
          </div>
          <p v-else class="p-0 m-0">コメントはありません。</p>
        </div>
      </div>
      <div class="col-12">
        <hr />
        <div class="page_files mb-3">
          <h5>添付ファイル</h5>
          <div v-if="page.files && page.files.length > 0" class="row g-3">
            <div
              v-for="file in page.files"
              :key="file.id"
              class="col-12 col-md-4 col-lg-3"
            >
              <div v-if="file.is_converted && isVideo(file)">
                <VideoPlayer :file="file" />
              </div>
              <div v-else>
                <ImageViewer :file="file" />
              </div>
            </div>
          </div>
          <p v-else class="p-0 m-0">添付ファイルはありません。</p>
        </div>
      </div>
    </div>
  </main>

  <Confirm ref="confirmDataDelete" @confirm-true="confirmDataDelete" />
  <PagePreview ref="pagePreviewModal" :page="page" />
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Navbar from '@/components/TheNabvarManage.vue'
import Confirm from '@/components/AppConfirm.vue'
import ImageViewer from '@/components/AppImageViewer.vue'
import VideoPlayer from '@/components/AppVideoPlayer.vue'
import PagePreview from '@/components/page/PagePreview.vue'
import { isImage, isVideo } from '@/utils/fileTypeUtils.js'

export default {
  name: 'ManagePageDetail',
  components: {
    Navbar,
    Confirm,
    ImageViewer,
    VideoPlayer,
    PagePreview,
  },

  props: {
    id: {
      type: Number,
      required: true,
    },
  },

  computed: {
    ...mapGetters({
      page: 'manage_page/detail',
    }),
  },

  async created() {
    await this.getData(this.id).catch((error) => {
      if (error.response.status) {
        window.setTimeout(() => {
          this.$router.push({
            name: 'ManagePageList',
          })
        }, this.$store.getters['alert/timeout'])
      }
    })
  },

  methods: {
    isImage,
    isVideo,
    ...mapActions({
      getData: 'manage_page/fetchData',
      deleteData: 'manage_page/deleteData',
    }),
    showPreview() {
      this.$refs.pagePreviewModal.showModal()
    },
    removeData() {
      this.$refs.confirmDataDelete.showConfirm(
        'ページを削除',
        'このページを削除しますか？'
      )
    },
    async confirmDataDelete() {
      await this.deleteData(this.id)
      this.$router.push({
        name: 'ManagePageList',
      })
    },
  },
}
</script>
