import api from '@/api'

// State（状態）
// https://next.vuex.vuejs.org/ja/guide/state.html
// 状態を監視するデータ
const state = () => ({})

// Getters（算出プロパティ）
// https://next.vuex.vuejs.org/ja/guide/getters.html
// コンポーネントから状態を取得するプロパティまたは関数
const getters = {}

// Mutations（状態変更）
// https://next.vuex.vuejs.org/ja/guide/mutations.html
// 状態を変更するメソッド
const mutations = {}

// Actions（処理）
// https://next.vuex.vuejs.org/ja/guide/actions.html
// コンポーネントからミューテーションを実行する関数
const actions = {
  /**
   * データを追加する
   *
   * @param {object}
   * @return {object}
   */
  async postData({ rootState }, form_data) {
    const page_id = rootState.manage_page.detail.id
    const response = await api.post(
      'manage/pages/' + page_id + '/links/',
      form_data
    )
    return response.data
  },
  /**
   * データを更新する
   *
   * @param {object}
   * @return {}
   */
  async patchData({}, form_data) {
    return
  },
  /**
   * データをまとめて処理する
   *
   * @param {array}
   * @return {void}
   */
  async bulkPostOrPatch({ dispatch }, array_data) {
    // 必要なデータのみにする
    const required_keys = ['id', 'title', 'url']
    let submit_data = {}

    array_data.forEach(async (form_data) => {
      submit_data = Object.fromEntries(
        Object.entries(form_data).filter(([key, value]) =>
          required_keys.includes(key)
        )
      )

      if ('id' in submit_data && submit_data.id) {
        await dispatch('patchData', submit_data)
      } else {
        await dispatch('postData', submit_data)
      }
    })
  },
  /**
   * データを削除する
   *
   * @param {Number}
   * @return {void}
   */
  async deleteData({}, id) {
    await api.delete('manage/links/' + id + '/')
  },
  /**
   * データをまとめて削除する
   *
   * @param {array}
   * @return {void}
   */
  async bulkDelete({ dispatch }, ids) {
    ids.forEach(async (id) => {
      await dispatch('deleteData', id)
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
