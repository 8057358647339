<template>
  <nav class="navbar navbar-expand navbar-dark bg-primary">
    <div class="container-fluid">
      <span class="navbar-brand"
        ><img
          src="@/assets/icon.svg"
          :alt="title"
          width="24"
          height="24"
          class="d-inline-block align-text-top me-2"
        />
        <slot name="navbarTitle">{{ title }}</slot>
      </span>
      <div class="w-50 d-none d-md-block">
        <form class="d-flex" role="search">
          <input
            v-model="query_string"
            class="form-control me-1"
            type="search"
            aria-label="Search"
          />
          <button
            v-on:click.prevent="searchSubmit"
            class="btn btn-primary"
            type="submit"
          >
            <i class="bi bi-search"></i>
          </button>
        </form>
      </div>

      <ul class="navbar-nav ms-auto">
        <li class="nav-item">
          <router-link
            class="nav-link"
            :to="{
              name: 'AppHome',
            }"
            ><i class="bi bi-house"></i
          ></router-link>
        </li>
        <li v-if="hasPermission('manage')" class="nav-item">
          <router-link
            class="nav-link"
            :to="{
              name: 'ManageDashboard',
            }"
            ><i class="bi bi-speedometer2"></i
          ></router-link>
        </li>
        <li class="nav-item">
          <a v-on:click.prevent="confirmLogout" class="nav-link" href="#"
            ><i class="bi bi-box-arrow-right"></i
          ></a>
        </li>
      </ul>
    </div>
  </nav>

  <Confirm ref="confirm" @confirm-true="confirmTrue" />
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Profile from '@/pages/AppProfile.vue'
import Confirm from '@/components/AppConfirm.vue'

export default {
  name: 'TheNavbar',
  components: {
    Profile,
    Confirm,
  },

  data() {
    return {
      title: process.env.VUE_APP_TITLE,
      query_string: '',
    }
  },
  computed: {
    ...mapGetters({
      query_params: 'frontend_page/query_params',
      hasPermission: 'auth/hasPermission',
    }),
  },

  watch: {
    'query_params.content': function (new_val, old_val) {
      this.query_string = new_val
    },
  },

  created() {
    this.query_string = this.query_params.content
  },

  methods: {
    ...mapActions({
      logout: 'auth/logout',
    }),
    confirmLogout() {
      this.$refs.confirm.showConfirm('', 'ログアウトしますか？')
    },
    async confirmTrue() {
      await this.logout()
      this.$router.push({
        name: 'AppLogin',
      })
    },
    searchSubmit() {
      this.$router.push({
        name: 'FrontPageList',
        query: { q: this.query_string },
      })
    },
  },
}
</script>
