<template>
  <Navbar />
  <main id="main" class="container-fluid">
    <header class="border-bottom py-2 py-sm-3">
      <div class="d-flex align-items-center justify-content-between">
        <h3 class="m-0">ページ一覧</h3>
        <div class="operation_links">
          <router-link
            :to="{
              name: 'ManagePageAdd',
            }"
            class="btn btn-primary"
            ><i class="bi bi-plus"></i>
            <span class="d-none d-sm-inline-block ms-1"
              >ページを作成する</span
            ></router-link
          >
        </div>
      </div>
    </header>

    <div class="d-flex align-items-center justify-content-between py-2">
      <div id="searchForm">
        <div class="d-none d-sm-block">
          <div class="input-group">
            <input
              v-model="query.title"
              type="text"
              class="form-control border-secondary border-end-0"
              placeholder="タイトルで検索"
            />
            <button
              v-on:click.prevent="resetQuery()"
              class="btn bg-white text-secondary border-secondary border-start-0"
              type="button"
            >
              <i class="bi bi-x"></i>
            </button>
            <button
              v-on:click.prevent="getQueryResult()"
              class="btn btn-secondary"
              type="button"
            >
              <i class="bi bi-search"></i>
            </button>
            <button
              class="btn btn-secondary"
              :class="{ 'btn-dark text-light': is_search }"
              type="button"
              data-bs-toggle="modal"
              data-bs-target="#pageSearchModal"
            >
              <i class="bi bi-sliders2"></i>
            </button>
          </div>
        </div>
        <div class="d-block d-sm-none">
          <button
            class="btn btn-outline-secondary"
            :class="{ 'btn-dark text-light': is_search }"
            type="button"
            data-bs-toggle="modal"
            data-bs-target="#pageSearchModal"
          >
            <i class="bi bi-search"></i>
          </button>
        </div>
      </div>
      <Pagination
        :count="count"
        :prev="prev"
        :next="next"
        :current_page="current_page"
        :total_pages="total_pages"
        :page_range="5"
        :show_count="true"
        @move-page="movePage"
      />
    </div>

    <PageSearchForm
      :query="query"
      @reset-query="resetQuery"
      @get-query-result="getQueryResult"
    />

    <!-- pages -->
    <div v-if="pages && pages.length > 0">
      <PageDataList :pages="pages" detail_link_name="ManagePageDetail" />
    </div>
    <p v-else class="p-0 py-2 m-0">データがありません。</p>
  </main>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Navbar from '@/components/TheNabvarManage.vue'
import Pagination from '@/components/AppPagination.vue'
import PageDataList from '@/components/page/PageDataList.vue'
import PageSearchForm from '@/components/page/PageSearchForm.vue'

export default {
  name: 'ManagePageList',
  components: {
    Navbar,
    Pagination,
    PageDataList,
    PageSearchForm,
  },

  data() {
    return {
      query: {},
    }
  },
  computed: {
    ...mapGetters({
      pages: 'manage_page/pages',
      count: 'manage_page/count',
      prev: 'manage_page/prev',
      next: 'manage_page/next',
      current_page: 'manage_page/current_page',
      total_pages: 'manage_page/total_pages',
      query_params: 'manage_page/query_params',
    }),
    is_search: function () {
      let search_query = Object.assign({}, this.query)
      delete search_query['page']
      return !!Object.keys(search_query).length
    },
  },

  async created() {
    await this.getData()
  },
  unmounted() {
    this.setQuery({})
  },

  methods: {
    ...mapActions({
      setQuery: 'manage_page/setQuery',
      getData: 'manage_page/fetchAll',
    }),
    movePage(page) {
      this.setQuery(Object.assign(this.query, { page: page })).then(() => {
        this.getData()
      })
    },
    getQueryResult() {
      this.setQuery(this.query).then(() => {
        this.getData()
      })
    },
    resetQuery() {
      this.query = {}
      this.getQueryResult()
    },
  },
}
</script>
