<template>
  <Navbar />
  <main id="main" class="container-fluid">
    <header class="border-bottom py-2 py-sm-3">
      <div class="d-flex align-items-center justify-content-between">
        <h3 class="m-0">操作マニュアル</h3>
      </div>
    </header>

    <div
      v-if="manuals && manuals.length > 0"
      class="d-flex align-items-center justify-content-end py-2"
    >
      <Pagination
        :count="count"
        :prev="prev"
        :next="next"
        :current_page="current_page"
        :total_pages="total_pages"
        :page_range="5"
        :show_count="true"
        @move-page="movePage"
      />
    </div>

    <!-- manuals -->
    <ul v-if="manuals && manuals.length > 0" class="list-group">
      <router-link
        v-for="manual in manuals"
        :key="manual.id"
        :to="{
          name: 'ManageManualDetail',
          params: { id: manual.id },
        }"
        class="list-group-item d-flex justify-content-between align-items-center p-3"
      >
        {{ manual.title }}
        <i class="bi bi-arrow-right-circle"></i>
      </router-link>
    </ul>
    <p v-else class="mt-3">該当するデータはありません。</p>
  </main>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Navbar from '@/components/TheNabvarManage.vue'
import Pagination from '@/components/AppPagination.vue'

export default {
  name: 'ManageManualList',
  components: {
    Navbar,
    Pagination,
  },

  data() {
    return {
      query: {},
    }
  },
  computed: {
    ...mapGetters({
      manuals: 'manage_manual/manuals',
      count: 'manage_manual/count',
      prev: 'manage_manual/prev',
      next: 'manage_manual/next',
      current_page: 'manage_manual/current_page',
      total_pages: 'manage_manual/total_pages',
      query_params: 'manage_manual/query_params',
    }),
    is_search: function () {
      let search_query = Object.assign({}, this.query)
      delete search_query['page']
      return !!Object.keys(search_query).length
    },
  },

  async created() {
    await this.getData()
  },
  unmounted() {
    this.setQuery({})
  },
  methods: {
    ...mapActions({
      setQuery: 'manage_manual/setQuery',
      getData: 'manage_manual/fetchAll',
    }),
    movePage(page) {
      this.setQuery(Object.assign(this.query, { page: page })).then(() => {
        this.getData()
      })
    },
    getQueryResult() {
      this.setQuery(this.query).then(() => {
        this.getData()
      })
    },
    resetQuery() {
      this.query = {}
      this.getQueryResult()
    },
  },
}
</script>
