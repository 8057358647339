<template>
  <Navbar />
  <main id="main" class="container-fluid">
    <header class="border-bottom py-2 py-sm-3">
      <div class="d-flex align-items-center justify-content-between">
        <h3 class="m-0">項目一覧</h3>
        <div class="operation_links">
          <a
            v-on:click.prevent="showSubcategoryCreateForm()"
            class="btn btn-primary"
            ><i class="bi bi-plus"></i>
            <span class="d-none d-sm-inline-block ms-1">項目を追加する</span></a
          >
        </div>
      </div>
    </header>

    <div class="d-flex align-items-center justify-content-end py-2">
      <Pagination
        :count="count"
        :prev="prev"
        :next="next"
        :current_page="current_page"
        :total_pages="total_pages"
        :page_range="0"
        :show_count="true"
        @move-page="movePage"
      />
    </div>

    <!-- subcategories -->
    <div v-if="subcategories && subcategories.length > 0">
      <div class="table-responsive">
        <table class="table table-bordered bg-white">
          <thead>
            <tr>
              <th scope="col" class="col-sm-2">大項目名</th>
              <th scope="col" class="col-sm-2">項目名</th>
              <th scope="col" class="col-sm-2">作成日時</th>
              <th scope="col" class="col-sm-2">更新日時</th>
              <th scope="col" class="col-sm-1"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="subcategory in subcategories" :key="subcategory.id">
              <td scope="row">{{ subcategory.category_name }}</td>
              <td>{{ subcategory.name }}</td>
              <td class="text-end">{{ subcategory.created_at }}</td>
              <td class="text-end">{{ subcategory.updated_at }}</td>
              <td class="text-end">
                <a
                  href="#"
                  v-on:click.prevent="showSubcategoryEditForm(subcategory)"
                  class="text-success"
                  >編集</a
                >｜<a
                  href="#"
                  v-on:click.prevent="removeData(subcategory.id)"
                  class="text-danger"
                  >削除</a
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <p v-else class="mt-3">該当するデータはありません。</p>

    <SubcategoryCreateForm ref="subcategoryCreateForm" @after-submit="getQueryResult" />
    <SubcategoryUpdateForm ref="subcategoryUpdateForm" @after-submit="getQueryResult" />
    <Confirm ref="confirmDataDelete" @confirm-true="confirmDataDelete" />
  </main>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Navbar from '@/components/TheNabvarManage.vue'
import Pagination from '@/components/AppPagination.vue'
import Confirm from '@/components/AppConfirm.vue'
import SubcategoryCreateForm from '@/components/subcategory/SubcategoryCreateForm.vue'
import SubcategoryUpdateForm from '@/components/subcategory/SubcategoryUpdateForm.vue'

export default {
  name: 'SubcategoryList',
  components: {
    Navbar,
    Pagination,
    Confirm,
    SubcategoryCreateForm,
    SubcategoryUpdateForm,
  },

  data() {
    return {
      current_id: null,
      query: {},
    }
  },
  computed: {
    ...mapGetters({
      subcategories: 'manage_subcategory/subcategories',
      count: 'manage_subcategory/count',
      prev: 'manage_subcategory/prev',
      next: 'manage_subcategory/next',
      current_page: 'manage_subcategory/current_page',
      total_pages: 'manage_subcategory/total_pages',
      query_params: 'manage_subcategory/query_params',
    }),
  },

  async created() {
    await this.getData()
  },

  methods: {
    ...mapActions({
      setQuery: 'manage_subcategory/setQuery',
      getData: 'manage_subcategory/fetchAll',
      deleteData: 'manage_subcategory/deleteData',
    }),
    movePage(page) {
      this.setQuery(Object.assign(this.query, { page: page })).then(() => {
        this.getData()
      })
    },
    getQueryResult() {
      this.setQuery(this.query).then(() => {
        this.getData()
      })
    },
    resetQuery() {
      this.query = {}
      this.getQueryResult()
    },
    showSubcategoryCreateForm() {
      this.$refs.subcategoryCreateForm.showModal()
    },
    showSubcategoryEditForm(data) {
      this.$refs.subcategoryUpdateForm.showModal(data)
    },
    removeData(id) {
      this.current_id = id
      this.$refs.confirmDataDelete.showConfirm(
        '項目を削除',
        'この項目を削除しますか？'
      )
    },
    async confirmDataDelete() {
      await this.deleteData(this.current_id)
      this.current_id = null
      this.getQueryResult()
    },
  },
}
</script>
