<template>
  <ul class="list-inline m-0">
    <li v-for="link in links" :key="link.id" class="list-inline-item me-3">
      <a :href="link.url" target="_blank"
        ><i class="bi bi-link-45deg me-1"></i>{{ link.title }}</a
      >
    </li>
  </ul>
</template>

<script>
export default {
  name: 'PageLinks',
  components: {},

  props: {
    links: {
      type: Array,
      required: true,
      default: [],
    },
  },
}
</script>
