<template>
  <Navbar />
  <main id="main" class="container-fluid">
    <header class="border-bottom py-2 py-sm-3">
      <div class="d-flex align-items-center justify-content-between">
        <h3 class="m-0">ユーザー一覧</h3>
        <div class="operation_links">
          <a v-on:click.prevent="showUserCreateForm()" class="btn btn-primary"
            ><i class="bi bi-plus"></i>
            <span class="d-none d-sm-inline-block ms-1"
              >ユーザーを追加する</span
            ></a
          >
        </div>
      </div>
    </header>

    <div class="d-flex align-items-center justify-content-between py-2">
      <div id="searchForm">
        <div class="d-none d-sm-block">
          <div class="input-group">
            <input
              v-model="query.full_name"
              type="text"
              class="form-control border-secondary border-end-0"
              placeholder="氏名で検索"
            />
            <button
              v-on:click.prevent="resetQuery()"
              class="btn bg-white text-secondary border-secondary border-start-0"
              type="button"
            >
              <i class="bi bi-x"></i>
            </button>
            <button
              v-on:click.prevent="getQueryResult()"
              class="btn btn-secondary"
              type="button"
            >
              <i class="bi bi-search"></i>
            </button>
            <button
              class="btn btn-secondary"
              :class="{ 'btn-dark text-light': is_search }"
              type="button"
              data-bs-toggle="modal"
              data-bs-target="#userSearchModal"
            >
              <i class="bi bi-sliders2"></i>
            </button>
          </div>
        </div>
        <div class="d-block d-sm-none">
          <button
            class="btn btn-outline-secondary"
            :class="{ 'btn-dark text-light': is_search }"
            type="button"
            data-bs-toggle="modal"
            data-bs-target="#userSearchModal"
          >
            <i class="bi bi-search"></i>
          </button>
        </div>
      </div>
      <Pagination
        :count="count"
        :prev="prev"
        :next="next"
        :current_page="current_page"
        :total_pages="total_pages"
        :page_range="0"
        :show_count="true"
        @move-page="movePage"
      />
    </div>

    <!-- users -->
    <div v-if="users && users.length > 0">
      <div class="table-responsive">
        <table class="table table-bordered bg-white">
          <thead>
            <tr>
              <th scope="col" class="col-sm-2">氏名</th>
              <th scope="col" class="col-sm-2">ユーザー名</th>
              <th scope="col" class="col-sm-2">権限グループ</th>
              <th scope="col" class="col-sm-1">有効</th>
              <th scope="col" class="col-sm-2">作成日時</th>
              <th scope="col" class="col-sm-2">最終ログイン日時</th>
              <th scope="col" class="col-sm-1"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="user in users" :key="user.id">
              <td scope="row">{{ user.full_name }}</td>
              <td>{{ user.username }}</td>
              <td>{{ user.groups.join(', ') }}</td>
              <td class="text-center">
                <i
                  v-if="user.is_active"
                  class="bi bi-check-circle-fill text-success"
                ></i>
              </td>
              <td class="text-end">{{ user.date_joined }}</td>
              <td class="text-end">{{ user.last_login }}</td>
              <td class="text-end">
                <span v-if="user.id != userData('id')">
                  <a
                    href="#"
                    v-on:click.prevent="showUserEditForm(user)"
                    class="text-success"
                    >編集</a
                  >｜<a
                    href="#"
                    v-on:click.prevent="removeData(user.id)"
                    class="text-danger"
                    >削除</a
                  >
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <p v-else class="mt-3">該当するデータはありません。</p>

    <!-- Modal -->
    <div
      class="modal fade"
      id="userSearchModal"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">詳細検索</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="row g-3">
              <div class="col-12">
                <label for="full_name" class="form-label">氏名</label>
                <input
                  v-model="query.full_name"
                  type="text"
                  class="form-control"
                  id="full_name"
                />
              </div>
              <div class="col-12">
                <label for="username" class="form-label">ユーザー名</label>
                <input
                  v-model="query.username"
                  type="text"
                  class="form-control"
                  id="username"
                />
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-outline-dark"
              data-bs-dismiss="modal"
              v-on:click.prevent="resetQuery()"
            >
              リセット
            </button>
            <button
              type="button"
              class="btn btn-dark"
              data-bs-dismiss="modal"
              v-on:click.prevent="getQueryResult()"
            >
              検索
            </button>
          </div>
        </div>
      </div>
    </div>

    <UserCreateForm ref="userCreateForm" @after-submit="getQueryResult" />
    <UserUpdateForm ref="userUpdateForm" @after-submit="getQueryResult" />
    <Confirm ref="confirmDataDelete" @confirm-true="confirmDataDelete" />
  </main>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Navbar from '@/components/TheNabvarManage.vue'
import Pagination from '@/components/AppPagination.vue'
import Confirm from '@/components/AppConfirm.vue'
import UserCreateForm from '@/components/user/UserCreateForm.vue'
import UserUpdateForm from '@/components/user/UserUpdateForm.vue'

export default {
  name: 'ManageUserList',
  components: {
    Navbar,
    Pagination,
    Confirm,
    UserCreateForm,
    UserUpdateForm,
  },

  data() {
    return {
      current_id: null,
      query: {},
    }
  },
  computed: {
    ...mapGetters({
      users: 'manage_user/users',
      count: 'manage_user/count',
      prev: 'manage_user/prev',
      next: 'manage_user/next',
      current_page: 'manage_user/current_page',
      total_pages: 'manage_user/total_pages',
      query_params: 'manage_user/query_params',
      userData: 'auth/userData',
    }),
    is_search: function () {
      let search_query = Object.assign({}, this.query)
      delete search_query['page']
      return !!Object.keys(search_query).length
    },
  },

  async created() {
    await this.getData()
  },
  unmounted() {
    this.setQuery({})
  },

  methods: {
    ...mapActions({
      setQuery: 'manage_user/setQuery',
      getData: 'manage_user/fetchAll',
      deleteData: 'manage_user/deleteData',
    }),
    movePage(page) {
      this.setQuery(Object.assign(this.query, { page: page })).then(() => {
        this.getData()
      })
    },
    getQueryResult() {
      this.setQuery(this.query).then(() => {
        this.getData()
      })
    },
    resetQuery() {
      this.query = {}
      this.getQueryResult()
    },
    showUserCreateForm() {
      this.$refs.userCreateForm.showModal()
    },
    showUserEditForm(data) {
      this.$refs.userUpdateForm.showModal(data)
    },
    removeData(id) {
      this.current_id = id
      this.$refs.confirmDataDelete.showConfirm(
        'ユーザーを削除',
        'このユーザーを削除しますか？'
      )
    },
    async confirmDataDelete() {
      if (this.current_id == this.userData('id')) {
        return
      }

      await this.deleteData(this.current_id)
      this.current_id = null
      this.getQueryResult()
    },
  },
}
</script>
