<template>
  <nav style="--bs-breadcrumb-divider: '&raquo;'" aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <router-link
          :to="{
            name: 'AppHome',
          }"
        >
          <i class="bi bi-house-fill"></i>
        </router-link>
      </li>
      <li
        v-for="(breadcrumb, index) in breadcrumbs"
        :key="index"
        class="breadcrumb-item active"
        aria-current="page"
      >
        <router-link
          v-if="index == 0 && breadcrumb.id"
          :to="{
            name: 'FrontCategoryList',
            params: { id: breadcrumb.id },
          }"
        >
          {{ breadcrumb.name }}
        </router-link>
        <span v-else>{{ breadcrumb.name }}</span>
      </li>
    </ol>
  </nav>
</template>

<script>
export default {
  name: 'AppBreadcrumb',
  components: {},

  props: {
    breadcrumbs: {
      type: Array,
      required: true,
    },
  },
}
</script>
