<template>
  <div class="page_files">
    <div class="d-flex align-items-center justify-content-between mb-2">
      <h5 class="m-0 p-0">添付ファイル</h5>
      <a @click.prevent="showForm" href="#" class="text-secondary"
        ><i class="bi bi-plus-circle-fill me-1"></i>追加</a
      >
    </div>
    <div v-if="files && files.length > 0" class="row g-3">
      <div
        v-for="(file, index) in files"
        :key="file.id"
        class="col-12 col-md-4 col-lg-3"
      >
        <div class="position-relative">
          <div v-if="(!file.id || file.is_converted) && isVideo(file)">
            <VideoPlayer :file="file" />
          </div>
          <div v-else>
            <ImageViewer :file="file" />
          </div>
          <div class="operation_button">
            <div class="position-absolute top-0 end-0 m-1">
              <button
                type="button"
                class="btn btn-outline-danger border-0"
                @click.prevent="removeData(index, file)"
              >
                <i class="bi bi-trash3"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <p v-else class="p-0 m-0">添付ファイルはありません。</p>
  </div>

  <div
    class="modal fade"
    id="pageFileModal"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg modal-dialog-centered">
      <div class="modal-content text-body">
        <div class="modal-header">
          <h5 class="modal-title">
            <slot name="modalTitle">ファイルを追加</slot>
          </h5>
        </div>
        <div class="modal-body">
          <div class="row g-2">
            <div class="col-12">
              <FileSelect
                ref="fileSelect"
                @selected-func="fileSelected"
                accept="image/jpeg,image/png,video/mp4"
              >
                <button
                  type="button"
                  class="btn btn-light me-1"
                  @click.prevent="fileSelect"
                >
                  <i class="bi bi-folder2 me-1"></i>ファイルを選択
                </button>
              </FileSelect>
              <span v-if="'file' in form_errors" class="text-danger">{{
                form_errors.file
              }}</span>
            </div>
            <div class="col-12">
              <div v-if="isVideo(form_data)">
                <VideoPlayer :file="form_data" />
              </div>
              <div v-else>
                <ImageViewer :file="form_data" :show_caption="false" />
              </div>
            </div>
            <div class="col-12">
              <label for="fileCaption" class="form-label">キャプション</label>
              <input
                v-model="form_data.caption"
                type="text"
                name="caption"
                id="fileCaption"
                class="form-control"
              />
              <span v-if="'caption' in form_errors" class="text-danger">{{
                form_errors.caption
              }}</span>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            class="btn btn-outline-dark"
            type="button"
            @click.prevent="hideForm"
          >
            キャンセル
          </button>
          <button
            class="btn btn-dark"
            type="button"
            @click.prevent="insertData"
          >
            追加
          </button>
        </div>
      </div>
    </div>
  </div>

  <Confirm ref="confirmDataDelete" @confirm-true="confirmDataDelete" />
</template>

<script>
import { mapActions } from 'vuex'
import { Modal } from 'bootstrap/dist/js/bootstrap.esm.min.js'
import Confirm from '@/components/AppConfirm.vue'
import ImageViewer from '@/components/AppImageViewer.vue'
import VideoPlayer from '@/components/AppVideoPlayer.vue'
import FileSelect from '@/components/page/FileSelect.vue'
import { isVideo } from '@/utils/fileTypeUtils.js'

export default {
  name: 'PageFileOperate',
  components: {
    Confirm,
    ImageViewer,
    VideoPlayer,
    FileSelect,
  },

  emits: ['removedData', 'insertData'],
  props: {
    files: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      modal: null,
      form_id: 'PageFileForm',
      form_data: {},
      current_data: {},
      form_errors: {},
    }
  },

  mounted() {
    this.modal = new Modal(document.getElementById('pageFileModal'))
  },
  beforeUnmount() {
    this.modal.hide()
  },

  methods: {
    isVideo,
    ...mapActions({
      deleteData: 'manage_file/deleteData',
    }),
    showForm() {
      this.modal.show()
    },
    async hideForm() {
      this.form_data = {}
      this.form_errors = {}
      this.modal.hide()
    },
    removeData(index, file) {
      this.current_data = {
        file: file,
        index: index,
      }
      this.$refs.confirmDataDelete.showConfirm(
        'ファイルを削除',
        'このファイルを削除しますか？\nこの操作は取消できません。'
      )
    },
    async confirmDataDelete() {
      if ('file' in this.current_data && 'id' in this.current_data.file) {
        await this.deleteData(this.current_data.file.id)
      }
      await this.$emit('removedData', this.current_data.index)
    },
    async insertData() {
      this.form_errors = {}

      if (!this.form_data.file_path || !this.form_data.file_type) {
        this.form_errors['file'] = 'ファイルを選択してください。'
      }
      if (this.form_data.caption && this.form_data.caption.length > 10) {
        this.form_errors['caption'] = 'キャプションは10文字までにしてください。'
      }

      if (Object.keys(this.form_errors).length == 0) {
        await this.$emit('insertData', this.form_data)
        this.hideForm()
      }
    },
    fileSelect() {
      this.$refs.fileSelect.fileEvent()
    },
    fileSelected(file, file_type) {
      this.form_data = {
        ...this.form_data,
        ...{
          file_path: file,
          file_type: file_type,
        },
      }
    },
  },
}
</script>
