<template>
  <Navbar />
  <main id="main" class="container-fluid">
    <div class="py-2 py-sm-3">
      <div class="row gy-3">
        <div class="col-lg-6">
          <div class="card border-secondary mb-3">
            <h5 class="card-header bg-secondary text-light">コンテンツ</h5>
            <div class="card-body">
              <div class="row row-cols-1 row-cols-md-2 g-2">
                <div class="col">
                  <router-link
                    :to="{
                      name: 'ManagePageList',
                    }"
                    class="btn btn-lg btn-outline-secondary w-100 py-3"
                    ><i class="bi bi-clipboard d-block"></i>ページ</router-link
                  >
                </div>
                <div class="col">
                  <router-link
                    :to="{
                      name: 'ManageSubcategoryList',
                    }"
                    class="btn btn-lg btn-outline-secondary w-100 py-3"
                    ><i class="bi bi-tag d-block"></i>項目</router-link
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="card border-dark mb-3">
            <h5 class="card-header bg-dark text-light">マスタ管理</h5>
            <div class="card-body">
              <div class="row row-cols-1 row-cols-md-2 gy-2">
                <div class="col">
                  <router-link
                    :to="{
                      name: 'ManageUserList',
                    }"
                    class="btn btn-lg btn-outline-dark w-100 py-3"
                    >ユーザー</router-link
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import Navbar from '@/components/TheNabvarManage.vue'

export default {
  name: 'ManageDashboard',
  components: {
    Navbar,
  },
}
</script>
