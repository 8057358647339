<template>
  <nav class="navbar navbar-expand navbar-light bg-light">
    <div class="container-fluid">
      <span class="navbar-brand">
        <slot name="formTitle"></slot>
      </span>
      <div class="d-flex operation-button">
        <button
          class="btn btn-outline-dark"
          @click.prevent="$emit('cancel')"
        >
          <span class="d-block d-sm-none"><i class="bi bi-x"></i></span>
          <span class="d-none d-sm-block">キャンセル</span>
        </button>
        <button class="btn btn-dark" @click.prevent="$emit('submit')">
          <span class="d-block d-sm-none"><i class="bi bi-send"></i></span>
          <span class="d-none d-sm-block">保存</span>
        </button>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'NavbarForm',
  emits: ['submit', 'cancel'],
}
</script>

<style scoped>
.operation-button button {
  margin-right: 0.25rem;
}
.operation-button button:last-child {
  margin: 0;
}
</style>
