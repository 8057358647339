<template>
  <button
    class="btn btn-outline-primary btn-sm"
    type="button"
    data-bs-toggle="offcanvas"
    data-bs-target="#pageComments"
    aria-controls="pageComments"
  >
    <span class="d-block d-sm-none"><i class="bi bi-chat-dots"></i></span
    ><span class="d-none d-sm-block"
      >コメント<span class="badge text-bg-primary ms-1">{{
        comments.length
      }}</span></span
    >
  </button>

  <div
    class="offcanvas offcanvas-end"
    tabindex="-1"
    id="pageComments"
    aria-labelledby="pageCommentsLabel"
  >
    <div class="offcanvas-header">
      <h5 class="offcanvas-title" id="pageCommentsLabel">コメント</h5>
      <button
        type="button"
        class="btn-close"
        data-bs-dismiss="offcanvas"
        aria-label="Close"
      ></button>
    </div>
    <div class="offcanvas-body">
      <div class="row row-cols-1 gy-2">
        <div v-for="comment in comments" :key="comment.id" class="col">
          <div class="card card-body">
            {{ comment.comment }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageComments',
  components: {},

  props: {
    comments: {
      type: Array,
      required: true,
      default: [],
    },
  },

  methods: {},
}
</script>
