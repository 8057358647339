import api from '@/api'

// State（状態）
// https://next.vuex.vuejs.org/ja/guide/state.html
// 状態を監視するデータ
const state = () => ({
  data: {},
  query_params: {},
  list: [],
  category_list: [],
})

// Getters（算出プロパティ）
// https://next.vuex.vuejs.org/ja/guide/getters.html
// コンポーネントから状態を取得するプロパティまたは関数
const getters = {
  subcategories: (state) => state.data.results,
  count: (state) => state.data.count,
  prev: (state) => state.data.previous,
  next: (state) => state.data.next,
  current_page: (state) => state.data.current_page,
  total_pages: (state) => state.data.total_pages,
  query_params: (state) => state.query_params,
  list: (state) => state.list,
  category_list: (state) => state.category_list,
}

// Mutations（状態変更）
// https://next.vuex.vuejs.org/ja/guide/mutations.html
// 状態を変更するメソッド
const mutations = {
  setData: (state, data) => (state.data = data),
  clearData: (state) => (state.data = []),
  setQueryParams: (state, query_params) => (state.query_params = query_params),
  clearQueryParams: (state) => (state.query_params = {}),
  setList: (state, list) => (state.list = list),
  clearList: (state) => (state.list = []),
  setCategoryList: (state, category_list) =>
    (state.category_list = category_list),
  clearCategoryList: (state) => (state.category_list = []),
}

// Actions（処理）
// https://next.vuex.vuejs.org/ja/guide/actions.html
// コンポーネントからミューテーションを実行する関数
const actions = {
  /**
   * すべてのデータをリセットする
   *
   * @return {void}
   */
  clearAll({ commit }) {
    return new Promise((resolve) => {
      commit('clearData')
      commit('clearQueryParams')
      commit('clearList')
      commit('clearCategoryList')
      resolve()
    })
  },
  /**
   * 一覧を取得する
   *
   * @return {void}
   */
  async fetchAll({ commit, getters }) {
    const response = await api.get('manage/subcategories/', {
      params: getters.query_params,
    })
    commit('setData', response.data)
  },
  /**
   * 検索クエリを保存する
   *
   * @param {object}
   * @return {void}
   */
  setQuery({ commit }, query_params) {
    return new Promise((resolve) => {
      commit('setQueryParams', query_params)
      resolve()
    })
  },
  /**
   * リストを取得する
   *
   * @return {void}
   */
  async fetchList({ state, commit }) {
    if (state.list.length > 0) {
      return
    }

    const response = await api.get('manage/subcategories/list/')
    commit('setList', response.data)
  },
  /**
   * 大項目リストを取得する
   *
   * @return {void}
   */
  async fetchCategoryList({ state, commit }) {
    if (state.category_list.length > 0) {
      return
    }

    const response = await api.get('manage/categories/list/')
    commit('setCategoryList', response.data)
  },
  /**
   * データを追加する
   *
   * @param {object}
   * @return {object}
   */
  async postData({}, form_data) {
    const response = await api.post('manage/subcategories/', form_data)
    return response.data
  },
  /**
   * データを更新する
   *
   * @param {object}
   * @return {}
   */
  async patchData({}, form_data) {
    const response = await api.patch(
      'manage/subcategories/' + form_data.id + '/',
      form_data
    )
    return response.data
  },
  /**
   * データを削除する
   *
   * @param {number}
   * @return {void}
   */
  async deleteData({ dispatch }, id) {
    try {
      await api.delete('manage/subcategories/' + id + '/')
    } catch (e) {
      if (e.response.status == 400) {
        dispatch(
          'alert/setErrorMessage',
          {
            message: e.response.data,
          },
          { root: true }
        )
      }
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
