<template>
  <div v-if="user && Object.keys(user).length > 0">
    <i class="bi bi-person" id="profilePopover"></i>

    <div class="d-none">
      <div id="profileContent">
        <div class="card-body">
          <h5 class="card-title mb-1">{{ user.display_name }}</h5>
          <h6 class="card-subtitle mb-2 text-muted">{{ user.username }}</h6>
          <p v-if="user.groups" class="card-text">
            {{ user.groups.join(', ') }}
          </p>
          <button
            type="button"
            class="btn btn-light w-100 mb-1"
            @click="showChangePassword"
          >
            パスワード変更
          </button>
          <button
            type="button"
            class="btn btn-light w-100"
            @click="showChangeName"
          >
            氏名変更
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { Popover } from 'bootstrap/dist/js/bootstrap.esm.min.js'

export default {
  name: 'AppProfile',
  components: {},
  emits: ['showChangePassword', 'showChangeName'],

  data() {
    return {
      popover: null,
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),
  },

  mounted() {
    this.popover = new Popover(document.querySelector('#profilePopover'), {
      html: true,
      offset: [-70, 10],
      placement: 'bottom',
      content: document.querySelector('#profileContent'),
    })
  },
  beforeUnmount() {
    this.popover.hide()
  },

  methods: {
    closePopover() {
      this.popover.hide()
    },
    showChangePassword() {
      this.$emit('showChangePassword')
      this.closePopover()
    },
    showChangeName() {
      this.$emit('showChangeName')
      this.closePopover()
    },
  },
}
</script>
