<template>
  <div id="appWrap">
    <Alert />
    <router-view></router-view>
    <Loading />
  </div>
</template>

<script>
import Alert from '@/components/AppAlert.vue'
import Loading from '@/components/AppLoading.vue'

export default {
  name: 'App',
  components: {
    Alert,
    Loading,
  },
}
</script>
