<template>
  <Navbar />
  <main id="main" class="bg-color container-fluid py-3">
    <div class="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-2">
      <div v-for="category in categories" :key="category.id" class="col">
        <div class="card h-100 border-0 bg-white" style="--bs-bg-opacity: 0.7">
          <img
            :src="display_image(category.image)"
            class="img-fluid rounded-start"
            :alt="category.name"
          />
          <div class="card-body">
            <h6 class="card-title text-center m-0">
              <router-link
                :to="{
                  name: 'FrontCategoryList',
                  params: { id: category.id },
                }"
                class="text-decoration-none fw-bold stretched-link"
              >
                {{ category.name }}
              </router-link>
            </h6>
          </div>
        </div>
      </div>
    </div>
  </main>

  <Footer />
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Navbar from '@/components/TheNavbar.vue'
import Footer from '@/components/TheFooter.vue'

export default {
  name: 'AppHome',
  components: {
    Navbar,
    Footer,
  },

  computed: {
    ...mapGetters({
      categories: 'frontend_category/list',
    }),
  },

  async created() {
    await this.getData()
  },

  methods: {
    ...mapActions({
      getData: 'frontend_category/fetchList',
    }),
    display_image(image_path) {
      if (image_path) {
        return image_path
      }
      return require('@/assets/no_image.svg')
    },
  },
}
</script>
