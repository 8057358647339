import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueCookies from 'vue-cookies'
import { plugin, defaultConfig } from '@formkit/vue'
import { generateClasses } from '@formkit/themes'
import { ja } from '@formkit/i18n'
import { initializeApp } from 'firebase/app'

import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-icons/font/bootstrap-icons.css'
import 'bootswatch/dist/minty/bootstrap.min.css'
import './assets/css/bootstrap-overwrite.css'

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
}
initializeApp(firebaseConfig)

const app = createApp(App)
app.use(router)
app.use(store)
app.use(VueCookies, {
  expireTimes: '1d',
  path: '/',
  domain: '',
  secure: true,
  sameSite: 'Lax',
})
app.use(
  plugin,
  defaultConfig({
    locales: { ja },
    locale: 'ja',
    config: {
      classes: generateClasses({
        global: {
          outer: '$reset',
          label: '$reset form-label',
          inner: '$reset',
          input: '$reset form-control',
          help: '$reset text-muted small',
          messages: '$reset list-unstyled mt-1',
          message: '$reset text-danger small',
          wrapper: '$reset',
        },
        submit: {
          input: '$reset btn btn-dark',
        },
        select: {
          input: '$reset form-select',
        },
      }),
    },
  })
)
app.mount('#app')
