<template>
  <footer id="footer" class="d-flex align-items-center justify-content-center">
    <div class="text-center">
      <p class="small m-0">&copy; {{ copyright }}</p>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'TheFooter',
  components: {},

  data() {
    return {
      copyright: process.env.VUE_APP_COPYRIGHT,
    }
  },
  computed: {},
}
</script>
