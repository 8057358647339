<template>
  <Navbar />
  <main
    id="main"
    class="container-fluid pt-3 bg-white"
    :style="backgroundImageInlineStyle"
  >
    <h3><i class="bi bi-tags me-2"></i>{{ category.name }}</h3>
    <div class="d-md-flex justify-content-between mb-1">
      <Breadcrumb :breadcrumbs="breadcrumbs" />
    </div>

    <div
      v-if="'subcategories' in category && category.subcategories.length > 0"
    >
      <div class="accordion" id="tableOfContents">
        <div
          v-for="subcategory in category.subcategories"
          :key="subcategory.id"
          class="accordion-item"
        >
          <h3 class="accordion-header" id="headingOne">
            <button
              class="accordion-button fw-bold collapsed"
              type="button"
              data-bs-toggle="collapse"
              :data-bs-target="'#collapse' + subcategory.id"
              aria-expanded="true"
              aria-controls="collapseOne"
            >
              <i class="bi bi-tag me-2"></i>{{ subcategory.name }}
            </button>
          </h3>
          <div
            :id="'collapse' + subcategory.id"
            class="accordion-collapse collapse"
            aria-labelledby="headingOne"
            data-bs-parent="#tableOfContents"
          >
            <div
              v-if="'pages' in subcategory && subcategory.pages.length > 0"
              class="list-group list-group-flush list-group-numbered"
            >
              <router-link
                v-for="page in subcategory.pages"
                :key="page.id"
                :to="{
                  name: 'FrontPageDetail',
                  params: { id: page.id },
                }"
                class="list-group-item list-group-item-action py-3"
              >
                {{ page.title }}
              </router-link>
            </div>
            <div v-else class="list-group list-group-flush">
              <p class="list-group-item py-3 m-0">ページがありません。</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <p v-else class="p-0 m-0">データがありません。</p>
  </main>

  <Footer />
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Navbar from '@/components/TheNavbar.vue'
import Footer from '@/components/TheFooter.vue'
import Breadcrumb from '@/components/AppBreadcrumb.vue'

export default {
  name: 'FrontCategoryList',
  components: {
    Navbar,
    Footer,
    Breadcrumb,
  },

  props: {
    id: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      breadcrumbs: [],
    }
  },

  computed: {
    ...mapGetters({
      category: 'frontend_category/detail',
    }),
    backgroundImageInlineStyle() {
      if (this.category.image) {
        return `background-image: url("${this.category.image}");`
      }
    },
  },

  async created() {
    await this.getData(this.id).catch((error) => {
      if (error.response.status) {
        window.setTimeout(() => {
          this.$router.push({
            name: 'AppHome',
          })
        }, this.$store.getters['alert/timeout'])
      }
    })
    this.breadcrumbs.push({
      id: this.category.id,
      name: this.category.name,
    })
  },

  methods: {
    ...mapActions({
      getData: 'frontend_category/fetchData',
    }),
  },
}
</script>

<style scoped>
#main {
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: 30% auto;
}
</style>
