<template>
  <slot></slot>
  <input
    type="file"
    class="d-none"
    :id="button_id"
    :accept="accept"
    @change="onFileChange"
  />
</template>

<script>
import { isImage, isVideo } from '@/utils/fileTypeUtils.js'

export default {
  name: 'FileSelect',

  emits: ['selectedFunc'],
  props: {
    accept: {
      type: String,
      required: false,
      default: 'image/jpeg,image/png',
    },
    max_file_size: {
      type: Number,
      required: false,
      default: 52428800,
    },
  },

  computed: {
    button_id: function () {
      let chars = 'abcdefghijklmnopqrstuvwxyz0123456789'
      let rand_str = ''
      for (let i = 0; i < 8; i++) {
        rand_str += chars.charAt(Math.floor(Math.random() * chars.length))
      }
      return 'file-' + rand_str
    },
  },

  methods: {
    isImage,
    isVideo,
    fileEvent() {
      document.getElementById(this.button_id).click()
    },
    async onFileChange(e) {
      this.$store.commit('loading/set')

      const files = e.target.files || e.dataTransfer.files
      let file = files[0]
      if (!this.fileCheck(file)) return

      if (this.isImage({ file_type: file.type })) {
        file = new Blob([file], { type: 'image/webp' })
      }
      if (this.isVideo({ file_type: file.type })) {
        file = new Blob([file], { type: file.type })
      }

      this.getDataURL(file)
        .then((dataURL) => {
          this.$emit('selectedFunc', dataURL, file.type)
        })
        .catch(() => console.log('ファイルが取得できませんでした。'))
        .finally(() => {
          this.$store.commit('loading/clear')
        })
    },
    getDataURL(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = (error) => reject(error)
      })
    },
    fileCheck(file) {
      if (!new RegExp(file.type).test(this.accept)) {
        this.$store.dispatch('alert/setErrorMessage', {
          message: '許可されていないファイル種別です。',
        })
        return false
      }

      if (file.size > this.max_file_size) {
        this.$store.dispatch('alert/setErrorMessage', {
          message: 'ファイル容量が上限(50MB)を超えています。',
        })
        return false
      }

      return true
    },
  },
}
</script>
