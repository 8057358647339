<template>
  <div class="page_wrapper clearfix">
    <div class="col-md-6 col-lg-5 float-md-end mb-3 ms-md-3">
      <div v-if="isVideo(file)">
        <VideoPlayer :file="file" />
      </div>
      <div v-else>
        <ImageViewer :file="file" />
      </div>
    </div>

    <div v-html="page.content" class="page_content ck-content"></div>
  </div>
</template>

<script>
import ImageViewer from '@/components/AppImageViewer.vue'
import VideoPlayer from '@/components/AppVideoPlayer.vue'
import { isVideo } from '@/utils/fileTypeUtils.js'

export default {
  name: 'TemplateOneMedia',
  components: {
    ImageViewer,
    VideoPlayer,
  },

  props: {
    page: {
      type: Object,
      required: true,
    },
  },

  computed: {
    file: function () {
      if (this.page.files[0]) {
        return this.page.files[0]
      } else {
        return {}
      }
    },
  },

  methods: {
    isVideo,
  },
}
</script>
