<template>
  <Navbar />
  <main id="main" class="container-fluid">
    <header class="border-bottom py-2 py-sm-3">
      <div class="d-flex align-items-center justify-content-between">
        <div class="operation_links">
          <router-link
            :to="{
              name: 'ManageManualList',
            }"
            class="btn btn-outline-dark"
            ><i class="bi bi-list"></i>
            <span class="d-none d-sm-inline-block ms-1"
              >一覧に戻る</span
            ></router-link
          >
        </div>
      </div>
    </header>

    <div v-if="manual && Object.keys(manual).length > 0">
      <div class="clearfix py-3">
        <h3 class="d-inline align-middle me-2">{{ manual.title }}</h3>
      </div>
      <div class="row g-3">
        <div v-html="convertedText(manual.summary)" class="col-12"></div>
        <div
          v-for="(step, index) in manual.manual_steps"
          :key="index"
          class="col-12"
        >
          <div class="border-bottom pb-2 mb-2">
            <div class="row">
              <div class="col-md-4 col-lg-3">
                <ImageViewer v-if="step.step_file" :file="step.step_file" />
              </div>

              <div class="col-md-8 col-lg-9">
                <div
                  v-html="convertedText(step.description)"
                  class="page_content"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <p v-else class="mt-3">該当するデータはありません。</p>
  </main>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Navbar from '@/components/TheNabvarManage.vue'
import ImageViewer from '@/components/AppImageViewer.vue'

export default {
  name: 'ManageManualDetail',
  components: {
    Navbar,
    ImageViewer,
  },

  props: {
    id: {
      type: Number,
      required: true,
    },
  },

  computed: {
    ...mapGetters({
      manual: 'manage_manual/detail',
    }),
  },

  async created() {
    await this.getData(this.id).catch((error) => {
      if (error.response.status) {
        window.setTimeout(() => {
          this.$router.push({
            name: 'ManageManualList',
          })
        }, this.$store.getters['alert/timeout'])
      }
    })
  },

  methods: {
    ...mapActions({
      getData: 'manage_manual/fetchData',
    }),
    convertedText(str) {
      if (!!str) {
        return str.replace(/\r?\n/g, '<br>')
      }
    },
  },
}
</script>
