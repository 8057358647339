<template>
  <Navbar @submit="formSubmit" @cancel="formCancel"
    ><template v-slot:formTitle>ページ作成</template></Navbar
  >
  <main id="main" class="container-fluid">
    <div class="py-3">
      <div class="card card-body">
        <PageForm :form_id="form_id" :form_data="form_data" />
      </div>
    </div>
  </main>
</template>

<script>
import Navbar from '@/components/TheNavbarForm.vue'
import PageForm from '@/components/page/PageForm.vue'

export default {
  name: 'ManagePageAdd',

  components: {
    Navbar,
    PageForm,
  },

  data() {
    return {
      form_id: 'pageForm',
      form_data: {
        title: '',
        content: '',
        is_publish: false,
        template_id: '',
        subcategory_id: '',
        files: [],
        links: [],
        comments: [],
      },
    }
  },

  methods: {
    formSubmit() {
      // フォーム検証と送信
      this.$formkit.submit(this.form_id)
    },
    formCancel() {
      this.$router.push({
        name: 'ManagePageList',
      })
    },
  },
}
</script>
