import { createStore } from 'vuex'
import auth from './modules/auth'
import alert from './modules/alert'
import loading from './modules/loading'
import frontend_category from './frontend/category'
import frontend_page from './frontend/page'
import manage_page from './manage/page'
import manage_file from './manage/file'
import manage_link from './manage/link'
import manage_comment from './manage/comment'
import manage_subcategory from './manage/subcategory'
import manage_template from './manage/template'
import manage_user from './manage/user'
import manage_manual from './manage/manual'

const store = createStore({
  actions: {
    clearAllStores({ dispatch }) {
      dispatch('auth/clearAll', null, { root: true })
      dispatch('alert/clearAll', null, { root: true })
      dispatch('loading/clearAll', null, { root: true })
      dispatch('frontend_category/clearAll', null, { root: true })
      dispatch('frontend_page/clearAll', null, { root: true })
      dispatch('manage_page/clearAll', null, { root: true })
      dispatch('manage_subcategory/clearAll', null, { root: true })
      dispatch('manage_template/clearAll', null, { root: true })
      dispatch('manage_user/clearAll', null, { root: true })
      dispatch('manage_manual/clearAll', null, { root: true })
    },
    clearDataStores({ dispatch }) {
      dispatch('frontend_category/clearAll', null, { root: true })
      dispatch('frontend_page/clearAll', null, { root: true })
      dispatch('manage_page/clearAll', null, { root: true })
      dispatch('manage_subcategory/clearAll', null, { root: true })
      dispatch('manage_template/clearAll', null, { root: true })
      dispatch('manage_user/clearAll', null, { root: true })
      dispatch('manage_manual/clearAll', null, { root: true })
    },
  },
  modules: {
    auth,
    alert,
    loading,
    frontend_category,
    frontend_page,
    manage_page,
    manage_file,
    manage_link,
    manage_comment,
    manage_subcategory,
    manage_template,
    manage_user,
    manage_manual,
  },
})

export default store
