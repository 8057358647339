import api from '@/api'

// State（状態）
// https://next.vuex.vuejs.org/ja/guide/state.html
// 状態を監視するデータ
const state = () => ({
  list: [],
  detail: {},
})

// Getters（算出プロパティ）
// https://next.vuex.vuejs.org/ja/guide/getters.html
// コンポーネントから状態を取得するプロパティまたは関数
const getters = {
  list: (state) => state.list,
  detail: (state) => state.detail,
}

// Mutations（状態変更）
// https://next.vuex.vuejs.org/ja/guide/mutations.html
// 状態を変更するメソッド
const mutations = {
  setList: (state, list) => (state.list = list),
  clearList: (state) => (state.list = []),
  setDetail: (state, detail) => (state.detail = detail),
  clearDetail: (state) => (state.detail = {}),
}

// Actions（処理）
// https://next.vuex.vuejs.org/ja/guide/actions.html
// コンポーネントからミューテーションを実行する関数
const actions = {
  /**
   * すべてのデータをリセットする
   *
   * @return {void}
   */
  clearAll({ commit }) {
    return new Promise((resolve) => {
      commit('clearList')
      commit('clearDetail')
      resolve()
    })
  },
  /**
   * リストを取得する
   *
   * @return {void}
   */
  async fetchList({ state, commit }) {
    if (state.list.length > 0) {
      return
    }

    const response = await api.get('frontend/categories/')
    commit('setList', response.data)
  },
  /**
   * 指定の詳細データを取得する
   *
   * @param {number}
   * @return {void}
   */
  async fetchData({ state, commit }, id) {
    if (Object.keys(state.detail).length > 0 && state.detail.id == id) {
      return
    }

    const response = await api.get(
      'frontend/categories/' + id + '/subcategory_pages/'
    )
    commit('setDetail', response.data)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
