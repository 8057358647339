<template>
  <div class="row g-3">
    <div class="col-lg-8">
      <FormKit
        type="form"
        @submit="formSubmit"
        :actions="false"
        :incomplete-message="false"
        :id="form_id"
      >
        <div class="row g-3">
          <div class="col-12">
            <FormKit
              v-model="form_data.title"
              type="text"
              name="title"
              label="タイトル"
              validation="required"
            />
          </div>
          <div class="col-12">
            <div class="form-check">
              <input
                v-model="form_data.is_publish"
                class="form-check-input"
                type="checkbox"
                id="page_is_publish"
              />
              <label class="form-check-label" for="page_is_publish">
                公開
              </label>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <FormKit
              v-model="form_data.template_id"
              type="select"
              label="テンプレート"
              name="template_id"
              validation="required"
            >
              <option
                v-for="template in templates"
                :key="template.id"
                :value="template.id"
              >
                {{ template.name }}
              </option>
            </FormKit>
          </div>
          <div class="col-12 col-md-6">
            <FormKit
              v-model="form_data.subcategory_id"
              type="select"
              label="項目"
              name="subcategory_id"
              validation="required"
            >
              <option
                v-for="subcategory in subcategories"
                :key="subcategory.id"
                :value="subcategory.id"
              >
                {{ subcategory.name }}
              </option>
            </FormKit>
          </div>
          <div class="col-12">
            <label for="content" class="form-label">内容</label>
            <ckeditor
              v-model="form_data.content"
              :editor="editor"
              :config="editorConfig"
            ></ckeditor>
          </div>
        </div>
      </FormKit>
    </div>
    <div class="col-lg-4">
      <PageLinkOperate
        v-if="form_data.links"
        :links="form_data.links"
        @removed-data="removedLink"
        @insert-data="insertLink"
      />
      <PageCommentOperate
        v-if="form_data.comments"
        :comments="form_data.comments"
        @removed-data="removedComment"
        @insert-data="insertComment"
      />
    </div>
    <div class="col-12">
      <hr />
      <PageFileOperate
        v-if="form_data.files"
        :files="form_data.files"
        @removed-data="removedFile"
        @insert-data="insertFile"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Editor from 'ckeditor5-custom-build/build/ckeditor'
import CKEditor from '@ckeditor/ckeditor5-vue'
import PageFileOperate from '@/components/page/PageFileOperate.vue'
import PageLinkOperate from '@/components/page/PageLinkOperate.vue'
import PageCommentOperate from '@/components/page/PageCommentOperate.vue'

export default {
  name: 'PageForm',
  components: {
    ckeditor: CKEditor.component,
    PageLinkOperate,
    PageFileOperate,
    PageCommentOperate,
  },

  props: {
    form_id: {
      type: String,
      required: false,
      default: 'pageForm',
    },
    form_data: {
      type: Object,
      required: false,
    },
  },

  data() {
    return {
      editor: Editor,
      editorConfig: {
        toolbar: [
          'bold',
          'italic',
          'underline',
          'highlight',
          'bulletedList',
          'numberedList',
          'blockQuote',
          'pageBreak',
          '|',
          'undo',
          'redo',
          'removeFormat',
        ],
      },
    }
  },
  computed: {
    ...mapGetters({
      subcategories: 'manage_subcategory/list',
      templates: 'manage_template/list',
    }),
  },

  async created() {
    await this.fetchSubcategoryList()
    await this.fetchTemplateList()
  },

  methods: {
    ...mapActions({
      postOrPatch: 'manage_page/postOrPatch',
      fetchSubcategoryList: 'manage_subcategory/fetchList',
      fetchTemplateList: 'manage_template/fetchList',
    }),
    async formSubmit() {
      try {
        const response = await this.postOrPatch(this.form_data)
        this.$store.dispatch('alert/setSuccessMessage', {
          message: '保存しました。',
        })
        window.setTimeout(async () => {
          await this.$router.push({
            name: 'ManagePageDetail',
            params: { id: response.id },
          })
        }, this.$store.getters['alert/timeout'])
      } catch (e) {
        if (e.response.status == 400) {
          // サーバー側の検証エラーを表示
          let errors = {}
          Object.keys(e.response.data).forEach((key) => {
            errors[key] = e.response.data[key][0]
          })
          this.$formkit.setErrors(this.form_id, [], errors)
        }
      }
    },
    removedLink(index) {
      this.form_data.links.splice(index, 1)
    },
    insertLink(link) {
      this.form_data.links.push(link)
    },
    removedComment(index) {
      this.form_data.comments.splice(index, 1)
    },
    insertComment(comment) {
      this.form_data.comments.push(comment)
    },
    removedFile(index) {
      this.form_data.files.splice(index, 1)
    },
    insertFile(file) {
      this.form_data.files.push(file)
    },
  },
}
</script>

<style>
.ck-editor__main .ck-content {
  min-height: 200px;
}
</style>
