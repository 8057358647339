<template>
  <div class="table-responsive">
    <table class="table table-bordered">
      <thead>
        <tr class="bg-light text-center">
          <th scope="col" class="col-sm-2">項目</th>
          <th scope="col" class="col-sm-3">タイトル</th>
          <th scope="col" class="col-sm-2">作成者</th>
          <th scope="col" class="col-sm-1">公開</th>
          <th scope="col" class="col-sm-2">作成日時</th>
          <th scope="col" class="col-sm-2">更新日時</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="page in pages" :key="page.id" class="text-nowrap">
          <td class="text-center">
            <span v-if="page.subcategory_name">{{ page.subcategory_name }}</span
            ><span v-else class="text-muted">項目なし</span>
          </td>
          <td>
            <router-link
              :to="{
                name: detail_link_name,
                params: { id: page.id },
              }"
              >{{ page.title }}</router-link
            >
          </td>
          <td class="text-center">
            <span v-if="page.author_display_name">{{
              page.author_display_name
            }}</span
            ><span v-else class="text-muted">削除済みユーザー</span>
          </td>
          <td class="text-center">
            <i
              v-if="page.is_publish"
              class="bi bi-check-circle-fill text-success"
            ></i>
          </td>
          <td class="text-end small">{{ page.created_at }}</td>
          <td class="text-end small">{{ page.updated_at }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: 'PageDataList',

  props: {
    pages: {
      type: Array,
      required: true,
    },
    detail_link_name: {
      type: String,
      required: false,
      default: 'OperatePageDetail',
    },
  },
}
</script>
