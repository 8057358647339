<template>
  <div
    class="modal fade"
    id="updateUserModal"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            <slot name="modalTitle">ユーザーを編集</slot>
          </h5>
        </div>
        <div class="modal-body">
          <FormKit
            type="form"
            @submit="formSubmit"
            :actions="false"
            :incomplete-message="false"
            :id="form_id"
          >
            <div class="row g-3">
              <div class="col-12">
                <label class="form-label">ユーザー名</label>
                <input
                  v-model="form_data.username"
                  type="text"
                  name="username"
                  class="form-control"
                  disabled
                />
                <div class="text-muted small">ユーザー名は変更できません。</div>
              </div>
              <div class="col-12">
                <FormKit
                  v-model="form_data.password"
                  type="password"
                  name="password"
                  label="パスワード"
                  help="変更する場合のみ入力してください。"
                />
              </div>
              <div class="col-6">
                <FormKit
                  v-model="form_data.last_name"
                  type="text"
                  name="last_name"
                  label="姓"
                />
              </div>
              <div class="col-6">
                <FormKit
                  v-model="form_data.first_name"
                  type="text"
                  name="first_name"
                  label="名"
                />
              </div>
              <div class="col-12">
                <FormKit
                  v-model="form_data.group_id"
                  type="select"
                  name="group_id"
                  label="権限グループ"
                  validation="required"
                >
                  <option
                    v-for="group in group_list"
                    :key="group.id"
                    :value="group.id"
                  >
                    {{ group.name }}
                  </option>
                </FormKit>
              </div>
              <div class="col-12">
                <div class="form-check">
                  <input
                    v-model="form_data.is_active"
                    class="form-check-input"
                    type="checkbox"
                    id="update_is_active"
                  />
                  <label class="form-check-label" for="update_is_active">
                    有効
                  </label>
                </div>
              </div>
            </div>
          </FormKit>
        </div>
        <div class="modal-footer">
          <button
            class="btn btn-outline-dark"
            type="button"
            @click.prevent="formClose"
          >
            キャンセル
          </button>
          <FormKit type="submit" :form="form_id"> 保存 </FormKit>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { Modal } from 'bootstrap/dist/js/bootstrap.esm.min.js'

export default {
  name: 'UserUpdateForm',
  components: {},

  emits: ['hiddenModal', 'afterSubmit'],

  data() {
    return {
      modal: null,
      form_id: 'UpdateUserForm',
      form_data: {},
    }
  },
  computed: {
    ...mapGetters({
      group_list: 'manage_user/group_list',
    }),
  },

  async created() {
    await this.fetchGroupList()
  },

  mounted() {
    this.modal = new Modal(document.getElementById('updateUserModal'))
    document
      .getElementById('updateUserModal')
      .addEventListener('hidden.bs.modal', () => {
        this.form_data = {}
        this.$emit('hiddenModal')
      })
  },
  beforeUnmount() {
    this.modal.hide()
  },

  methods: {
    ...mapActions({
      patchData: 'manage_user/patchData',
      fetchGroupList: 'manage_user/fetchGroupList',
    }),
    showModal(user) {
      if (user) {
        this.form_data = Object.assign({}, user)
        this.form_data.group_id = this.group_list.find(
          (g) => g.name === user.groups[0]
        ).id
      }
      this.modal.show()
    },
    async formSubmit() {
      try {
        await this.patchData(this.form_data)
        await this.$emit('afterSubmit')
        this.formClose()
      } catch (e) {
        if (e.response.status == 400) {
          // サーバー側の検証エラーを表示
          let errors = {}
          Object.keys(e.response.data).forEach((key) => {
            errors[key] = e.response.data[key][0]
          })
          this.$formkit.setErrors(this.form_id, [], errors)
        }
      }
    },
    formClose() {
      this.$formkit.reset(this.form_id)
      this.modal.hide()
    },
  },
}
</script>
