import ManageHome from '@/pages/manage/Home.vue'
import ManageDashboard from '@/pages/manage/Dashboard.vue'
import ManagePageList from '@/pages/manage/PageList.vue'
import ManagePageDetail from '@/pages/manage/PageDetail.vue'
import ManagePageAdd from '@/pages/manage/PageAdd.vue'
import ManagePageEdit from '@/pages/manage/PageEdit.vue'
import ManageUserList from '@/pages/manage/UserList.vue'
import ManageSubcategoryList from '@/pages/manage/SubcategoryList.vue'
import ManageManualList from '@/pages/manage/ManualList.vue'
import ManageManualDetail from '@/pages/manage/ManualDetail.vue'

export default {
  routes: [
    {
      path: '/manage',
      component: ManageHome,
      name: 'ManageHome',
      meta: { requiresAuth: true },
      children: [
        {
          path: '',
          component: ManageDashboard,
          name: 'ManageDashboard',
        },
        {
          path: 'pages',
          component: ManagePageList,
          name: 'ManagePageList',
        },
        {
          path: 'pages/add',
          component: ManagePageAdd,
          name: 'ManagePageAdd',
        },
        {
          path: 'pages/:id([0-9]+)',
          component: ManagePageDetail,
          name: 'ManagePageDetail',
          props: (route) => ({ id: Number(route.params.id) }),
        },
        {
          path: 'pages/:id([0-9]+)/edit',
          component: ManagePageEdit,
          name: 'ManagePageEdit',
          props: (route) => ({ id: Number(route.params.id) }),
        },
        {
          path: 'users',
          component: ManageUserList,
          name: 'ManageUserList',
        },
        {
          path: 'subcategories',
          component: ManageSubcategoryList,
          name: 'ManageSubcategoryList',
        },
        {
          path: 'manuals',
          component: ManageManualList,
          name: 'ManageManualList',
        },
        {
          path: 'manuals/:id([0-9]+)',
          component: ManageManualDetail,
          name: 'ManageManualDetail',
          props: (route) => ({ id: Number(route.params.id) }),
        },
      ],
    },
  ],
}
