<template>
  <div
    class="modal fade"
    id="changeNameModal"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content text-body">
        <div class="modal-header">
          <h5 class="modal-title">
            <slot name="modalTitle">氏名を変更</slot>
          </h5>
        </div>
        <div class="modal-body">
          <FormKit
            type="form"
            @submit="formSubmit"
            :actions="false"
            :incomplete-message="false"
            :id="form_id"
          >
            <div class="row g-3">
              <div class="col-6">
                <FormKit
                  v-model="user.last_name"
                  type="text"
                  label="姓"
                  validation="required"
                />
              </div>
              <div class="col-6">
                <FormKit
                  v-model="user.first_name"
                  type="text"
                  label="名"
                  validation="required"
                />
              </div>
            </div>
          </FormKit>
        </div>
        <div class="modal-footer">
          <button
            class="btn btn-outline-dark"
            type="button"
            @click.prevent="formClose"
          >
            キャンセル
          </button>
          <FormKit type="submit" :form="form_id"> 保存 </FormKit>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { Modal } from 'bootstrap/dist/js/bootstrap.esm.min.js'

export default {
  name: 'ProfileChangeName',
  components: {},

  emits: ['hiddenModal', 'afterSubmit'],

  data() {
    return {
      modal: null,
      form_id: 'ChangeNameForm',
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),
  },

  mounted() {
    this.modal = new Modal(document.getElementById('changeNameModal'))
    document
      .getElementById('changeNameModal')
      .addEventListener('hidden.bs.modal', () => {
        this.$emit('hiddenModal')
      })
  },
  beforeUnmount() {
    this.modal.hide()
  },

  methods: {
    ...mapActions({
      patchData: 'auth/patchData',
    }),
    showModal() {
      this.modal.show()
    },
    async formSubmit() {
      try {
        await this.patchData(this.user)
        await this.$emit('afterSubmit')
        this.formClose()
      } catch (e) {
        if (e.response.status == 400) {
          // サーバー側の検証エラーを表示
          let errors = {}
          Object.keys(e.response.data).forEach((key) => {
            errors[key] = e.response.data[key][0]
          })
          this.$formkit.setErrors(this.form_id, [], errors)
        }
      }
    },
    formClose() {
      this.$formkit.reset(this.form_id)
      this.modal.hide()
    },
  },
}
</script>
