<template>
  <nav class="navbar navbar-expand navbar-light bg-light">
    <div class="container-fluid">
      <span class="navbar-brand"
        ><img
          src="@/assets/icon_dark.svg"
          :alt="title"
          width="24"
          height="24"
          class="d-inline-block align-text-top me-2"
        />
        <slot name="navbarTitle">{{ title }}</slot>
      </span>

      <ul class="navbar-nav ms-auto">
        <li class="nav-item">
          <router-link
            class="nav-link"
            :to="{
              name: 'AppHome',
            }"
            ><i class="bi bi-house"></i
          ></router-link>
        </li>
        <li class="nav-item">
          <router-link
            class="nav-link"
            :to="{
              name: 'ManageDashboard',
            }"
            ><i class="bi bi-speedometer2"></i
          ></router-link>
        </li>
        <li class="nav-item">
          <a href="#" class="nav-link"
            ><Profile
              @show-change-password="showChangePassword"
              @show-change-name="showChangeName"
          /></a>
        </li>
        <li class="nav-item">
          <router-link
            class="nav-link"
            :to="{
              name: 'ManageManualList',
            }"
            ><i class="bi bi-question-circle"></i
          ></router-link>
        </li>
        <li class="nav-item">
          <a v-on:click.prevent="confirmLogout" class="nav-link" href="#"
            ><i class="bi bi-box-arrow-right"></i
          ></a>
        </li>
      </ul>
    </div>
  </nav>

  <Confirm ref="confirm" @confirm-true="confirmTrue" />
  <ProfileChangePassword ref="profileChangePassword" />
  <ProfileChangeName ref="profileChangeName" />
</template>

<script>
import { mapActions } from 'vuex'
import Profile from '@/pages/AppProfile.vue'
import Confirm from '@/components/AppConfirm.vue'
import ProfileChangePassword from '@/components/ProfileChangePassword.vue'
import ProfileChangeName from '@/components/ProfileChangeName.vue'

export default {
  name: 'TheNavbarManage',
  components: {
    Profile,
    Confirm,
    ProfileChangePassword,
    ProfileChangeName,
  },

  data() {
    return {
      title: process.env.VUE_APP_TITLE,
    }
  },

  methods: {
    ...mapActions({
      logout: 'auth/logout',
    }),
    confirmLogout() {
      this.$refs.confirm.showConfirm('', 'ログアウトしますか？')
    },
    async confirmTrue() {
      await this.logout()
      this.$router.push({
        name: 'AppLogin',
      })
    },
    searchSubmit() {
      this.$router.push({
        name: 'FrontPageList',
      })
    },
    showChangePassword() {
      this.$refs.profileChangePassword.showModal()
    },
    showChangeName() {
      this.$refs.profileChangeName.showModal()
    },
  },
}
</script>
