<template>
  <div class="page_content">
    <div class="row g-3">
      <div class="col-12 col-lg-10">
        <!-- Slider main container -->
        <div class="swiper swiper-main">
          <!-- Additional required wrapper -->
          <div class="swiper-wrapper">
            <!-- slides -->
            <div class="swiper-slide">
              <div v-html="page.content" class="page_content ck-content"></div>
            </div>
            <div v-for="file in page.files" :key="file.id" class="swiper-slide">
              <div v-if="isImage(file)">
                <ImageViewer
                  ref="imageViewerElem"
                  :file="file"
                  :show_lightbox="false"
                />
              </div>
              <div v-if="isVideo(file)">
                <VideoPlayer ref="videoPlayerElem" :file="file" />
              </div>
            </div>
          </div>
          <!-- navigation -->
          <div class="swiper-button-prev"></div>
          <div class="swiper-button-next"></div>
        </div>
      </div>
      <div class="col-12 col-lg-2">
        <!-- Slider main container -->
        <div class="swiper swiper-thumb">
          <!-- Additional required wrapper -->
          <div class="swiper-wrapper">
            <!-- slides -->
            <div class="swiper-slide">
              <div class="ratio ratio-16x9 bg-primary">
                <img
                  :src="require('@/assets/content.svg')"
                  class="img-fluid"
                  :alt="page.title"
                />
              </div>
            </div>
            <div v-for="file in page.files" :key="file.id" class="swiper-slide">
              <div class="border border-primary">
                <ImageViewer
                  :file="file"
                  :show_caption="false"
                  :show_lightbox="false"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper, { Navigation, Thumbs } from 'swiper'
import ImageViewer from '@/components/AppImageViewer.vue'
import VideoPlayer from '@/components/AppVideoPlayer.vue'
import { isImage, isVideo } from '@/utils/fileTypeUtils.js'

// import Swiper and modules styles
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/thumbs'

export default {
  name: 'TemplateSlider',
  components: {
    ImageViewer,
    VideoPlayer,
  },

  props: {
    page: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      swiperThumb: null,
      swiper: null,
    }
  },

  mounted() {
    this.swiperThumb = new Swiper('.swiper-thumb', {
      spaceBetween: 5,
      slidesPerView: 3,
      freeMode: true,
      watchSlidesProgress: true,
      breakpoints: {
        768: {
          slidesPerView: 4,
        },
        1024: {
          slidesPerView: 5,
          direction: 'vertical',
          autoHeight: true,
        },
      },
    })
    this.swiper = new Swiper('.swiper-main', {
      modules: [Navigation, Thumbs],
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      thumbs: {
        swiper: this.swiperThumb,
      },
      // https://swiperjs.com/swiper-api#events
      on: {
        slideChange: () => {
          this.stopVideoPlay()
        },
      },
    })
  },
  beforeDestroy() {
    this.disposeVideoPlay()
  },

  methods: {
    isImage,
    isVideo,
    slideTo(index) {
      this.stopVideoPlay()
      if (this.swiper) {
        this.swiper.slideTo(index)
      }
    },
    stopVideoPlay() {
      if (this.$refs.videoPlayerElem) {
        this.$refs.videoPlayerElem.forEach((player) => {
          player.pause()
        })
      }
    },
    disposeVideoPlay() {
      if (this.$refs.videoPlayerElem) {
        this.$refs.videoPlayerElem.forEach((player) => {
          player.dispose()
        })
      }
    },
  },
}
</script>

<style scoped>
.swiper {
  width: 100%;
  height: 100%;
}

.swiper-thumb .swiper-slide {
  opacity: 0.4;
}

.swiper-thumb .swiper-slide-thumb-active {
  opacity: 1;
}
</style>
