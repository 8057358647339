import api from '@/api'
import {
  getMimeTypeFromDataURL,
  generateRandomFileName,
} from '@/utils/fileTypeUtils.js'
import {
  getStorage,
  ref,
  uploadString,
  connectStorageEmulator,
} from 'firebase/storage'

// State（状態）
// https://next.vuex.vuejs.org/ja/guide/state.html
// 状態を監視するデータ
const state = () => ({})

// Getters（算出プロパティ）
// https://next.vuex.vuejs.org/ja/guide/getters.html
// コンポーネントから状態を取得するプロパティまたは関数
const getters = {}

// Mutations（状態変更）
// https://next.vuex.vuejs.org/ja/guide/mutations.html
// 状態を変更するメソッド
const mutations = {}

// Actions（処理）
// https://next.vuex.vuejs.org/ja/guide/actions.html
// コンポーネントからミューテーションを実行する関数
const actions = {
  /**
   * データを追加する
   *
   * @param {object}
   * @return {object}
   */
  async postData({ dispatch, rootState }, form_data) {
    const page_id = rootState.manage_page.detail.id
    const fileType = getMimeTypeFromDataURL(form_data['file_path'])
    const fileName = generateRandomFileName(fileType)
    const filePath = `page/file/${fileName}`
    const response = await api.post('manage/pages/' + page_id + '/files/', {
      file_path: filePath,
      file_name: fileName,
      file_type: fileType,
      file_size: '',
      caption: form_data['caption'] ? form_data['caption'] : null,
    })
    try {
      await uploadString(
        ref(getFirebaseStorage(), filePath),
        form_data['file_path'],
        'data_url'
      )
      return response.data
    } catch (error) {
      // アップロードが失敗した場合の処理
      await dispatch('deleteData', response.data.id)
      throw error
    }
  },
  /**
   * データを更新する
   *
   * @param {object}
   * @return {}
   */
  async patchData({}, form_data) {
    return
  },
  /**
   * データをまとめて処理する
   *
   * @param {array}
   * @return {object}
   */
  async bulkPostOrPatch({ dispatch }, array_data) {
    // 必要なデータのみにする
    const required_keys = ['id', 'file_path', 'caption']
    let submit_data = {}

    array_data.forEach(async (form_data) => {
      submit_data = Object.fromEntries(
        Object.entries(form_data).filter(([key, value]) =>
          required_keys.includes(key)
        )
      )

      if ('id' in submit_data && submit_data.id) {
        await dispatch('patchData', submit_data)
      } else {
        await dispatch('postData', submit_data)
      }
    })
  },
  /**
   * データを削除する
   *
   * @param {Number}
   * @return {void}
   */
  async deleteData({}, id) {
    await api.delete('manage/files/' + id + '/')
  },
}

const getFirebaseStorage = () => {
  const storage = getStorage()
  if (process.env.NODE_ENV === 'development') {
    connectStorageEmulator(storage, 'localhost', 9199)
  }
  return storage
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
