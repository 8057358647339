<template>
  <!-- Modal -->
  <div class="modal fade" id="pageSearchModal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">詳細検索</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row g-3">
            <div class="col-12">
              <label for="subcategory_id" class="form-label">項目</label>
              <select
                v-model="query.subcategory_id"
                class="form-select"
                id="subcategory_id"
              >
                <option :value="undefined">---</option>
                <option
                  v-for="subcategory in subcategories"
                  :key="subcategory.id"
                  :value="subcategory.id"
                >
                  {{ subcategory.name }}
                </option>
              </select>
            </div>
            <div class="col-12">
              <label for="title" class="form-label">タイトル</label>
              <input
                v-model="query.title"
                type="text"
                class="form-control"
                id="title"
              />
            </div>
            <div class="col-12">
              <div class="form-check">
                <input
                  v-model="query.is_publish"
                  class="form-check-input"
                  type="checkbox"
                  id="is_publish"
                />
                <label class="form-check-label" for="is_publish"> 公開 </label>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-outline-dark"
            data-bs-dismiss="modal"
            v-on:click.prevent="$emit('resetQuery')"
          >
            リセット
          </button>
          <button
            type="button"
            class="btn btn-dark"
            data-bs-dismiss="modal"
            v-on:click.prevent="$emit('getQueryResult')"
          >
            検索
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'PageSearchForm',
  emits: ['resetQuery', 'getQueryResult'],
  props: {
    query: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...mapGetters({
      subcategories: 'manage_subcategory/list',
    }),
  },

  async created() {
    await this.fetchCategoryList()
  },

  methods: {
    ...mapActions({
      fetchCategoryList: 'manage_subcategory/fetchList',
    }),
  },
}
</script>
