import api from '@/api'

// State（状態）
// https://next.vuex.vuejs.org/ja/guide/state.html
// 状態を監視するデータ
const state = () => ({
  data: {},
  detail: {},
  query_params: {},
})

// Getters（算出プロパティ）
// https://next.vuex.vuejs.org/ja/guide/getters.html
// コンポーネントから状態を取得するプロパティまたは関数
const getters = {
  pages: (state) => state.data.results,
  count: (state) => state.data.count,
  prev: (state) => state.data.previous,
  next: (state) => state.data.next,
  current_page: (state) => state.data.current_page,
  total_pages: (state) => state.data.total_pages,
  detail: (state) => state.detail,
  query_params: (state) => state.query_params,
}

// Mutations（状態変更）
// https://next.vuex.vuejs.org/ja/guide/mutations.html
// 状態を変更するメソッド
const mutations = {
  setData: (state, data) => (state.data = data),
  clearData: (state) => (state.data = []),
  setDetail: (state, detail) => (state.detail = detail),
  clearDetail: (state) => (state.detail = {}),
  setQueryParams: (state, query_params) => (state.query_params = query_params),
  clearQueryParams: (state) => (state.query_params = {}),
}

// Actions（処理）
// https://next.vuex.vuejs.org/ja/guide/actions.html
// コンポーネントからミューテーションを実行する関数
const actions = {
  /**
   * すべてのデータをリセットする
   *
   * @return {void}
   */
  clearAll({ commit }) {
    return new Promise((resolve) => {
      commit('clearData')
      commit('clearDetail')
      commit('clearQueryParams')
      resolve()
    })
  },
  /**
   * 一覧を取得する
   *
   * @return {void}
   */
  async fetchAll({ commit, getters }) {
    const response = await api.get('manage/pages/', {
      params: getters.query_params,
    })
    commit('setData', response.data)
  },
  /**
   * 指定の詳細データを取得する
   *
   * @param {number}
   * @return {void}
   */
  async fetchData({ state, commit }, id) {
    if (Object.keys(state.detail).length > 0 && state.detail.id == id) {
      return
    }

    const response = await api.get('manage/pages/' + id + '/')
    commit('setDetail', response.data)
  },
  /**
   * 検索クエリを保存する
   *
   * @param {object}
   * @return {void}
   */
  setQuery({ commit }, query_params) {
    return new Promise((resolve) => {
      commit('setQueryParams', query_params)
      resolve()
    })
  },
  /**
   * データを追加する
   *
   * @param {object}
   * @return {object}
   */
  async postData({ commit }, form_data) {
    const response = await api.post('manage/pages/', form_data)
    commit('setDetail', response.data)
    return response.data
  },
  /**
   * データを更新する
   *
   * @param {object}
   * @return {object}
   */
  async patchData({ commit }, form_data) {
    const response = await api.patch(
      'manage/pages/' + form_data.id + '/',
      form_data
    )
    commit('setDetail', response.data)
    return response.data
  },
  /**
   * データを作成または更新する
   *
   * @param {object}
   * @return {object}
   */
  async postOrPatch({ state, commit, dispatch }, form_data) {
    // 必要なデータのみにする
    const required_keys = [
      'id',
      'title',
      'is_publish',
      'content',
      'subcategory_id',
      'template_id',
    ]
    const submit_data = Object.fromEntries(
      Object.entries(form_data).filter(([key, value]) =>
        required_keys.includes(key)
      )
    )

    try {
      if ('id' in submit_data && submit_data['id']) {
        await dispatch('relatedDataDelete', form_data)
        await dispatch('patchData', submit_data)
      } else {
        await dispatch('postData', submit_data)
      }
      await dispatch('relatedDataPost', form_data)
      const detail = state.detail
      await commit('clearDetail')
      return detail
    } catch (error) {
      console.error(error)
    }
  },
  /**
   * 関連データを作成または更新する
   *
   * @param {object}
   * @return {void}
   */
  async relatedDataPost({ dispatch }, form_data) {
    // 関連ファイル
    if ('files' in form_data) {
      await dispatch('manage_file/bulkPostOrPatch', form_data['files'], {
        root: true,
      })
    }
    // リンク
    if ('links' in form_data) {
      await dispatch('manage_link/bulkPostOrPatch', form_data['links'], {
        root: true,
      })
    }
    // コメント
    if ('comments' in form_data) {
      await dispatch('manage_comment/bulkPostOrPatch', form_data['comments'], {
        root: true,
      })
    }
  },
  /**
   * 関連データを削除する
   *
   * @param {object}
   * @return {void}
   */
  async relatedDataDelete({ state, dispatch }, form_data) {
    // リンク
    if ('links' in form_data) {
      const diff_link_ids = await getDiffIds(
        state.detail.links,
        form_data['links']
      )
      if (diff_link_ids.length > 0) {
        await dispatch('manage_link/bulkDelete', diff_link_ids, {
          root: true,
        })
      }
    }
    // コメント
    if ('comments' in form_data) {
      const diff_comment_ids = await getDiffIds(
        state.detail.comments,
        form_data['comments']
      )
      if (diff_comment_ids.length > 0) {
        await dispatch('manage_comment/bulkDelete', diff_comment_ids, {
          root: true,
        })
      }
    }
  },
  /**
   * データを削除する
   *
   * @param {number}
   * @return {void}
   */
  async deleteData({ dispatch }, id) {
    await api.delete('manage/pages/' + id + '/')
    dispatch('clearAll')
  },
}

function getDiffIds(current_data, form_data) {
  const ids = form_data.map((obj) => {
    if (obj.hasOwnProperty('id')) {
      return obj.id
    }
  })
  const current_ids = current_data.map((obj) => {
    if (obj.hasOwnProperty('id')) {
      return obj.id
    }
  })
  return current_ids.filter((x) => !ids.includes(x))
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
