<template>
  <div
    class="modal fade"
    id="pagePreviewModal"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-fullscreen">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            <slot name="modalTitle">プレビュー</slot>
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="clearfix">
            <div class="float-end">
              <PageComments :comments="preview_page.comments" />
            </div>
            <h3>{{ preview_page.title }}</h3>
          </div>

          <div class="shadow-sm rounded p-2 p-md-3 p-lg-4 mb-2">
            <TemplateOneMedia
              v-if="preview_page.template_slug == 'one_media'"
              :page="preview_page"
            />
            <TemplateTwoMedia
              v-if="preview_page.template_slug == 'two_media'"
              :page="preview_page"
            />
            <TemplateThreeMedia
              v-if="preview_page.template_slug == 'three_media'"
              ref="TemplateThreeMedia"
              :page="preview_page"
            />
            <TemplateTextOnly
              v-if="preview_page.template_slug == 'text_only'"
              :page="preview_page"
            />
            <TemplateTwoAndTwo
              v-if="preview_page.template_slug == 'two_two'"
              ref="TemplateTwoAndTwo"
              :page="preview_page"
            />
            <TemplateSlider
              v-if="preview_page.template_slug == 'slider'"
              ref="templateSlider"
              :page="preview_page"
            />
          </div>

          <PageLinks :links="preview_page.links" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from 'bootstrap/dist/js/bootstrap.esm.min.js'
import PageComments from '@/components/page/PageComments.vue'
import PageLinks from '@/components/page/PageLinks.vue'
import TemplateOneMedia from '@/components/template/TemplateOneMedia.vue'
import TemplateTwoMedia from '@/components/template/TemplateTwoMedia.vue'
import TemplateThreeMedia from '@/components/template/TemplateThreeMedia.vue'
import TemplateTextOnly from '@/components/template/TemplateTextOnly.vue'
import TemplateTwoAndTwo from '@/components/template/TemplateTwoAndTwo.vue'
import TemplateSlider from '@/components/template/TemplateSlider.vue'

export default {
  name: 'PagePreview',
  components: {
    PageComments,
    PageLinks,
    TemplateOneMedia,
    TemplateTwoMedia,
    TemplateThreeMedia,
    TemplateTextOnly,
    TemplateTwoAndTwo,
    TemplateSlider,
  },

  emits: ['hiddenModal'],

  props: {
    page: {
      type: Object,
      required: true,
    },
  },

  computed: {
    preview_page: function () {
      const page = Object.assign({}, this.page)
      if (this.page.files && this.page.files.length > 0) {
        page.files = this.page.files.filter((obj) => {
          return obj.is_converted === true
        })
      }
      page.content = this.replaceContent()
      return page
    },
  },

  mounted() {
    this.modal = new Modal(document.getElementById('pagePreviewModal'))
    document
      .getElementById('pagePreviewModal')
      .addEventListener('hidden.bs.modal', () => {
        this.resetSlider()
        this.$emit('hiddenModal')
      })
  },
  beforeUnmount() {
    this.hideModal()
  },
  methods: {
    showModal() {
      this.modal.show()
    },
    hideModal() {
      this.modal.hide()
    },
    resetSlider() {
      if (this.$refs.templateSlider) {
        this.$refs.templateSlider.slideTo(0)
      }
    },
    replaceContent() {
      if (!this.page.content) return ''
      const regex = /<div\s+class\s*=\s*["']page-break["'].*?>.*?<\/div>/gi
      const replacement = '<!--page_break-->'
      return this.page.content.replace(regex, replacement)
    },
  },
}
</script>
